import styled, { createGlobalStyle, css } from 'styled-components';
import { StyleConstants } from './StyleConstants';
import { mediaMax, mediaBetween } from 'styles/media';

const activeStatuses = ['active', 'online', 'approved'];

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  body.fontLoaded {
    font-family: Poppins, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  main {
    overflow-x: hidden;
    flex: 1;
  }

  .full-width {
    width: 100%;
  }

  .content-head-title {
    color: #51545D;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
  }

  .cursorPointer {
    cursor: pointer;
  }

  .textRight {
    text-align: right;
  }

  .textLeft {
    text-align: left;
  }

  .textCenter {
    text-align: center;
  }

  .justifyRight {
    justify-content: right;
  }

  .boxShadowNone {
    box-shadow: none !important;
  }

  .imgCol {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .borderBottom {
    border-bottom: 1px solid #E1E1E1;
  }

  .boldFont {
    font-weight: bold;
  }

  .text-basic {
    color: ${StyleConstants.TEXT_COLOR};
    font-size: ${StyleConstants.TEXT_SIZE};
  }

  .red-text-basic {
    color: red;
    font-size: ${StyleConstants.TEXT_SIZE};
  }

  .active-lab {
    background-color: deepskyblue !important;
    color: white !important;
  }

  .border-red {
    border: solid 1px red;
  }

  /*Margins*/
  .p-1 {
    padding: 10px;
  }

  .m-1 {
    margin: 10px;
  }

  .pl-1 {
    padding-left: 10px;
  }

  .pr-1 {
    padding-right: 10px;
  }

  .pl-2 {
    padding-left: 15px;
  }

  .pr-2 {
    padding-right: 20px;
  }

  .pb-1 {
    padding-bottom: 10px;
  }

  .pb-2 {
    padding-bottom: 15px;
  }

  .pt-1 {
    padding-top: 10px;
  }

  .pt-2 {
    padding-top: 20px;
  }

  .ml-2 {
    margin-left: 20px;
  }

  .ml-1 {
    margin-left: 10px;
  }

  .mr-1 {
    margin-right: 10px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mt-1 {
    margin-top: 10px;
  }

  .mt-3 {
    margin-top: 30px;
  }

  .mb-1 {
    margin-bottom: 10px;
  }

  .mb-5px {
    margin-bottom: 5px;
  }

  .mb-2 {
    margin-bottom: 20px;
  }

  .mb-3 {
    margin-bottom: 30px;
  }

  .mt-1 {
    margin-top: 10px;
  }

  .mt-2 {
    margin-top: 20px;
  }

  input:disabled {
    background-color: white;
  }
  /*_______*/
`;

export const Div = styled.div``;
export const Span = styled.span``;

export const MainSection = styled.section`
  position: absolute;
  left: ${props =>
    props.isMobileView
      ? 0
      : !props.isOpen
      ? StyleConstants.ASIDE_WIDTH_CLOSED
      : StyleConstants.ASIDE_WIDTH_OPEN};
  transition: ${props => (!props.isMobileView ? '.2s' : '0s')};
  padding-right: ${props => (props.isMobileView ? 0 : StyleConstants.ASIDE_WIDTH_CLOSED)};
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
`;

export const DashboardWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100vh;
`;

export const IconInputWrapper = styled.div`
  position: relative;

  .input--icon {
    position: absolute;
    ${props => (!props.direction || props.direction === 'left' ? { left: 8 } : { right: 8 })};
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const ContentShadowAndWhite = styled.div`
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
`;

export const FlagsRow = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-bottom: 15px;
`;

export const FlagsCol = styled.div`
  font-size: smaller;
  padding-bottom: 8px;
  font-size: 13px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  color: #575962;
  border-bottom: ${props => props.borderBottom};
  cursor: ${props => props.cursor || 'pointer'};
`;

export const ContentHead = styled.div`
  padding: 1vw 3vw;
  margin-bottom: ${props => props.mb || 0}px;
  border-bottom: ${props => (props.withBorderBottom ? '1px solid #E1E1E1' : 'none')};
  ${props =>
    props.flex
      ? {
          display: 'flex',
          justifyContent: props.flex,
          alignItems: 'center',
        }
      : {}}
`;

export const ContentBody = styled.div`
  padding: 15px;
`;

export const IconButton = styled.div`
  cursor: pointer;
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.4'};
  border: ${props =>
    props.noneBorder ? 'none' : `1px solid ${StyleConstants.INPUT_BORDER_COLOR}`};
  border-radius: ${props => props.radius || '4px'};
  color: ${StyleConstants.TEXT_COLOR};
  background-color: #ffffff;
  width: ${StyleConstants.ACTION_ICON_WRAPPER_SIZE};
  height: ${props => props.height || StyleConstants.ACTION_ICON_WRAPPER_SIZE};
  display: flex;
  align-items: ${props => (props.noneBorder ? 'right' : 'center')};
  justify-content: ${props => (props.noneBorder ? 'right' : 'center')};
  z-index: 2;
  padding: ${props => props.padding};
`;

export const AlignFlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputIconWrapper = styled.div`
  display: flex;

  & .select-icon {
    margin-left: -1px;
  }

  & .MuiInputBase-root,
  & .MuiSelect-root,
  & .MuiFormControl-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex: 1;
  }
`;

export const SelectIconWrapper = styled.div`
  display: flex;

  & .select-icon {
    margin-left: -1px;
  }

  & .MuiInputBase-root,
  & .MuiSelect-root,
  & .MuiFormControl-root {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    flex: 1;
    width: 150px;
  }
`;

export const NotResultFound = styled.div`
  text-align: center;
  margin-top: 30px;
  color: ${StyleConstants.TEXT_COLOR};
  font-size: 20px;
  font-weight: bold;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`;

export const RequiredText = styled.p`
  color: red;
  text-align: center;
  margin-top: 5px;
  font-size: smaller;
`;

export const LabelOfSelector = styled.h3`
  color: #717171;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 3px;
`;

export const LikeTitle = styled.p`
  color: ${StyleConstants.TEXT_COLOR};
  margin-top: 5px;
  font-size: ${StyleConstants.TEXT_SIZE};
  margin-bottom: 5px;

  &.mobile-top-mobile-remove {
    ${mediaMax.sm`
    	margin-top: 0;
    `}
  }
`;

export const LabelTag = styled.span`
  border: 1px solid #dfe4e4;
  border-radius: ${StyleConstants.INPUT_RADIUS};
  background-color: #f7f7f7;
  padding: 6px 10px;
  color: ${StyleConstants.TEXT_COLOR};
  font-size: ${StyleConstants.TEXT_SIZE};
  display: inline-block;
  margin: 0 5px 5px 0;
  cursor: pointer;
  ${({ disabled }) => disabled && 'opacity: 0.5; pointer-events: none'};
`;

export const DescriptionTextArea = styled.div`
  padding: 10.5px 10px;
  color: rgba(0, 0, 0, 0.6);
  cursor: default;
  background-color: #f7f7f7;
  position: relative;
  border: 1px solid ${StyleConstants.INPUT_BORDER_COLOR};
  border-top-left-radius: ${StyleConstants.INPUT_RADIUS};
  border-bottom-left-radius: ${StyleConstants.INPUT_RADIUS};
  font-size: ${StyleConstants.INPUT_TEXT_SIZE};

  & .added-value {
    background-color: rgb(201 225 160);
    padding: 0 2px;
    border-radius: ${StyleConstants.INPUT_RADIUS};
  }
  & .removed-value {
    background-color: rgb(255 174 184);
    padding: 0 2px;
    border-radius: ${StyleConstants.INPUT_RADIUS};
    text-decoration: line-through;
  }
`;

export const ForbiddenWordsWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  line-height: 18px;
  border: 1px solid #e1e1e1;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  padding: ${({ textArea }) => (textArea ? '3px 9px 3px 9px' : '6px 9px 6px 9px')};
`;

export const StatusDot = styled.span`
  height: 10px;
  width: 10px;
  background: ${({ status }) =>
    activeStatuses.includes(status)
      ? '-webkit-linear-gradient(bottom right, #258700 0%, #6cfa65 100%)'
      : '-webkit-linear-gradient(bottom right, #d4072e 0%, #fa9e65  100%)'};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const MediasForSelectWrapper = css`
  ${mediaBetween.xs_sm`
    grid-template-columns: repeat(auto-fill, 50%);
    grid-gap: 0px;
  `};
  ${mediaMax.xss`
    grid-template-columns: repeat(auto-fill, 100%);
    grid-gap: 0px;
  `};
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
  ${MediasForSelectWrapper};
`;

export const SelectItem = styled.div`
  ${mediaMax.sm`
    padding: 2px;
  `}
`;
