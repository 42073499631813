import { makeStyles } from '@material-ui/core/styles';

export const useLinkAndFilterStyles = makeStyles({
  root: {
    '& .error': {
      color: 'red',
      fontSize: '10px',
      marginLeft: '5px',
    },
    '& .level-group .select-wrapper': {
      width: '100% !important',
    },
    '& .flex': {
      display: 'flex',
    },
    '& .select-wrapper': {
      width: '256px !important',
      marginBottom: '10px',

      '& .disabled > .MuiSelect-select.Mui-disabled': {
        cursor: 'not-allowed !important',
      },

      '& .select-title': {
        height: '20px',
      },
    },
    '& .full-width': {
      width: '100%',
    },
    '& .m-20': {
      margin: '0 20px',
    },
  },
});

export const useDialogStyles = makeStyles({
  root: {
    '& .dialog-actions': {
      justifyContent: 'space-between',
      padding: '8px 15px',
    },

    '& .input-title': {
      margin: '5px 0',
      color: '#575962',
      '& span': {
        color: 'red',
      },
    },

    '& .input-field': {
      marginBottom: '15px',
      '& input': {
        padding: '12px',
        color: '#575962',
      },
      '& .box': {
        width: '250px',
        color: '#575962',
        fontSize: '14px',
        padding: '10px 0',
        textAlign: 'center',
        background: '#717171',
        border: '1px solid #E1E1E1',
        borderRadius: '5px 0 0 5px',
        backgroundColor: '#F5F5F5;',
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E1E1E1',
      },
    },

    '& .input-field.flex': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '0 5px 5px 0',
      },
    },

    '& .first-step-title': {
      fontSize: '17px',
      margin: '0 0 20px 0',
      color: '#575962',
      '& span': {
        fontWeight: 700,
      },
    },

    '& button': {
      margin: '0 10px',
    },

    '& button.blueGray': {
      backgroundColor: '#b8b3e6',
    },
    '& button.purple': {
      backgroundColor: '#7167cd',
    },
  },
  dialogTitle: {
    padding: '16px 24px 0 24px',
    '& div': {
      color: '#575962',
      fontWeight: 700,
      margin: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .close': {
      cursor: 'pointer',
    },
    '& hr': {
      display: 'block',
      height: '1px',
      border: 0,
      borderTop: '1px solid #E1E1E1',
      padding: 0,
    },
  },
});
