import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    display: 'flex',
    padding: '16px 20px',
    justifyContent: 'space-between',
    '& .title': {
      fontSize: '20px',
    },
    '& .add-icon': {
      color: '#29C0A3',
      cursor: 'pointer',
    },
  },
});
