import { memo } from 'react';
import clsx from 'clsx';
import isEqual from 'react-fast-compare';
import { FormControl, MenuItem, Select as MUISelect } from '@material-ui/core';

import { LikeTitle } from 'styles/global-styles';
import { useTranslator } from 'utils/translator';

const getTranslations = (t, type, label) =>
  t(type === 'filters' ? `filters_${label}_title` : label);

const Select = ({
  type,
  label,
  disabled,
  className,
  multiple,
  options = [],
  fullWidth = true,
  value = multiple ? [] : '',
  handleChange = () => {},
}) => {
  const { t } = useTranslator();

  return (
    <div className={clsx('select-wrapper', 'full-width', className)}>
      <FormControl disabled={disabled} fullWidth={fullWidth}>
        <LikeTitle className="select-title">{label}</LikeTitle>
        <MUISelect
          displayEmpty
          value={value}
          multiple={multiple}
          id="demo-simple-select"
          onChange={handleChange}
          className={clsx('select_size__sm', { disabled })}
          labelId="demo-simple-select-label"
          renderValue={selected => {
            if (selected.length === 0) {
              return 'Select';
            }

            return multiple
              ? selected.map(item => getTranslations(t, type, item)).join(', ')
              : getTranslations(t, type, selected);
          }}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={label} value={value}>
              {getTranslations(t, type, label)}
            </MenuItem>
          ))}
        </MUISelect>
      </FormControl>
    </div>
  );
};

export default memo(Select, isEqual);
