import { FlagsCol, FlagsRow } from 'styles/global-styles';
import { flags } from 'utils/constants';

const Flag = ({ name, setActiveFlag, activeFlag, Icon, title }) => (
  <FlagsCol
    style={{ cursor: 'notAllowed' }}
    onClick={() => setActiveFlag(name)}
    borderBottom={activeFlag === name ? 'solid 2px #6F68CA' : 'none'}
  >
    <Icon className="mr-1" height="10px" />
    {title}
  </FlagsCol>
);

const Flags = ({ activeFlag, setActiveFlag }) => (
  <FlagsRow>
    {flags.reduce((acc, el) => {
      const currentElement = (
        <Flag key={el.name} activeFlag={activeFlag} setActiveFlag={setActiveFlag} {...el} />
      );

      if (!el.disabled) {
        if (el.name === 'value_nl') {
          acc.unshift(currentElement);
        } else {
          acc.push(currentElement);
        }
      }
      return acc;
    }, [])}
  </FlagsRow>
);

export default Flags;
