import { memo } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';

import { ButtonMargin } from './styles';

const DeleteEdit = ({ row, index, editList, deleteList }) => (
  <>
    <ButtonMargin className="cursorPointer">
      <MdModeEdit
        color="#7167CD"
        size={20}
        onClick={() => {
          editList(row, index);
        }}
      />
    </ButtonMargin>
    <AiFillDelete
      color="red"
      size={20}
      onClick={() => {
        deleteList(row['_id'], index);
      }}
      className="cursorPointer"
    />
  </>
);

export default memo(DeleteEdit);
