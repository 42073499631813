export const LOCATION_PREFIX = '[LOCATION]';

const getMetaPath = ({ selectedCategory, selectedLocation, selectedLabel }) => {
  if (!selectedLocation) return '';
  let metaPath;

  if (selectedCategory) {
    metaPath = `/${selectedCategory.slug}/${LOCATION_PREFIX}`;

    if (selectedLabel) {
      metaPath = `/${selectedCategory.slug}/${selectedLabel.slug}/[LOCATION]`;
    }
  } else {
    metaPath = '/[LOCATION]';
  }

  return { metaPath, location: selectedLocation };
};

export default getMetaPath;
