const getListingHeading = ({
  city,
  slug,
  label,
  category,
  isValidURL,
  settlement,
  searchedValue,
}) => {
  if (!isValidURL) return '';

  const isSearch = slug.includes('search');

  if (isSearch) {
    return `[AD_COUNT] ${settlement ? 'in' : 'voor'} ${searchedValue}`;
  }

  if (!label && !city && !category) {
    return '';
  }

  const labelTitle = label ? '- [LABEL]' : '';
  const cityTitle = city ? 'in [CITY]' : '';
  const categoryTitle = category ? ' voor [CATEGORY]' : '';

  return `[AD_COUNT] Resultaten ${categoryTitle} ${labelTitle} ${cityTitle}`.trim();
};

export default getListingHeading;
