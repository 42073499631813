import { format } from 'date-fns';
import DeleteEdit from 'app/components/Dictionary/components/DeleteEdit';

import success from 'images/success.svg';

export const getColumns = (editList, openModal) => [
  {
    id: 'path',
    label: 'URL',
  },
  {
    id: 'has_dead_link',
    label: 'Dead links',
  },
  {
    id: 'date_created',
    label: 'Created on',
  },
  {
    id: 'date_modified',
    label: 'Edited on',
  },
  {
    id: 'actions',
    label: 'Actions',
    type: 'renderComponent',

    Component: ({ row, index }) => (
      <DeleteEdit index={index} row={row} editList={editList} deleteList={openModal} />
    ),
  },
];

export const getRows = pages =>
  pages?.map(({ _id, path, has_dead_link, date_created, date_modified }) => ({
    _id,
    date_modified: date_modified ? format(new Date(date_modified), 'dd-MM-yyyy') : '-',
    date_created: date_created ? format(new Date(date_created), 'dd-MM-yyyy') : '-',
    path,
    has_dead_link: <div>{has_dead_link ? <img alt="has_dead_link" src={success} /> : '-'}</div>,
    hasDeadLink: has_dead_link,
  }));
