import { useState, useEffect, useCallback, memo, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { cmsService } from 'services/cms';
import getValidRoute from 'utils/getValidRoute';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { throwErrorActions } from 'store/slices/throwError';

import { ReactComponent as FaClose } from 'images/faClose.svg';

import Content from '../Content';
import SEOData from './SEOData';
import LinkAndFilter from './LinkAndFilter';
import { useDialogStyles } from './styles';

import { updateState } from '../utils';
import { getMetadataWithLocation } from 'utils/meta';
import useMount from 'hooks/useMount';
import useDebounce from 'hooks/useDebounce';

const steps = {
  1: LinkAndFilter,
  2: SEOData,
  3: Content,
};

const stepsTitle = {
  1: 'Link&Filter',
  2: 'SEO data',
  3: 'Content',
};

const defaultState = {
  filters: {
    primary: {},
    secondary: {},
    category: { label: 'Category' },
  },
  path: '',
  content: { ads: '', position: '' },
  title: { en: '', nl: '' },
  description: { en: '', nl: '' },
  heading: { en: '', nl: '' },
};

const LandingPageManagementDialog = ({ selectedId, adFilters, handleCloseDialog }) => {
  const [data, setData] = useState(defaultState);
  const [currentStep, setCurrentStep] = useState(1);
  const [isExistPath, setIsExistPath] = useState(false);

  const dispatch = useDispatch();
  const { root, dialogTitle } = useDialogStyles();

  const debouncedPath = useDebounce(data.path, 500);

  const currentPath = useRef(null);

  const urlStructure = useDebounceCallback({
    callback: getValidRoute,
    value: data?.path,
    defaultValue: {},
    args: {
      slug: data?.path.split('?')[0].split('/'),
      categories: adFilters.categories,
      locationsData: adFilters.location,
    },
  });

  const Component = steps[currentStep];

  const handleDataChange = useCallback(data => {
    setData(prev => updateState(prev, data));
  }, []);

  const resetData = useCallback(
    excluded =>
      setData(prev => ({
        ...defaultState,
        ...(excluded &&
          excluded.reduce((acc, el) => {
            acc[el] = prev[el];
            return acc;
          }, {})),
      })),
    [],
  );

  const { path } = data;
  const { heading, metaData } = urlStructure || {};

  const { metaTitle = '', metaDescription = '' } = getMetadataWithLocation(metaData, data?.path);

  useEffect(() => {
    if (debouncedPath && ((isEditMode && path && path !== currentPath.current) || !isEditMode)) {
      (async () => {
        try {
          const { data } = await cmsService.getIsExistPath(path);

          setIsExistPath(data.exists);
        } catch (e) {
          dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
        }
      })();
    } else if (isExistPath) {
      setIsExistPath(false);
    }
    // Don't update dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPath]);

  useMount(() => {
    if (selectedId) {
      (async () => {
        try {
          const { data } = await cmsService.getPage(selectedId);

          currentPath.current = data.path;

          setData(data);
        } catch (e) {
          dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
        }
      })();
    }
  });

  const { title: currentTitle, heading: currentHeading, description: currentDescription } = data;

  useEffect(() => {
    handleDataChange({
      title: { nl: currentTitle.nl || metaTitle, en: currentTitle.nl || '' },
      heading: { nl: currentHeading.nl || heading, en: currentHeading.en || '' },
      description: {
        nl: currentDescription.nl || metaDescription,
        en: currentDescription.en || '',
      },
    });
  }, [
    currentDescription.en,
    currentDescription.nl,
    currentHeading.en,
    currentHeading.nl,
    currentTitle.nl,
    handleDataChange,
    heading,
    metaDescription,
    metaTitle,
  ]);

  const isEditMode = !!selectedId;
  const title = `${isEditMode ? 'Edit' : 'Create'} Landing page`;

  const handleCreatePage = useCallback(async () => {
    try {
      const {
        data: { result },
      } = await cmsService[isEditMode ? 'updatePage' : 'createPage'](data, selectedId);

      if (result) {
        dispatch(
          throwErrorActions.updateSuccess(
            `Page has been successfully ${isEditMode ? 'edited' : 'created'}!`,
          ),
        );
        handleCloseDialog(true);
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
    }
  }, [data, dispatch, handleCloseDialog, isEditMode, selectedId]);

  return (
    <MUIDialog open maxWidth="lg" className={root} disableBackdropClick onClose={handleCloseDialog}>
      <DialogTitle className={dialogTitle} id="alert-dialog-title">
        <div>
          <div className="title">{title}</div>
          <div className="close">
            <FaClose width="20px" height="20px" onClick={handleCloseDialog} />
          </div>
        </div>
        <hr />
      </DialogTitle>
      <DialogContent style={{ minWidth: '860px' }}>
        <div className="first-step-title">
          <span>Step {currentStep}:</span> {stepsTitle[currentStep]}
        </div>
        <Component
          data={data}
          resetData={resetData}
          adFilters={adFilters}
          isEditMode={isEditMode}
          isExistPath={isExistPath}
          urlStructure={urlStructure}
          handleDataChange={handleDataChange}
        />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <div>
          {currentStep > 1 && (
            <Button
              className={clsx({ purple: currentStep === 2, blueGray: currentStep === 3 })}
              variant="contained"
              onClick={() =>
                currentStep === 2 &&
                handleDataChange({
                  title: { en: data.title.nl },
                  description: { en: data.description.nl },
                  heading: { en: data.heading.nl },
                })
              }
              color="primary"
            >
              {currentStep === 2 ? 'Autocomplete' : 'Preview'}
            </Button>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() =>
              currentStep === 1 ? handleCloseDialog() : setCurrentStep(prev => prev - 1)
            }
            color="secondary"
          >
            {currentStep === 1 ? 'Cancel' : 'Prev step'}
          </Button>
          <Button
            disabled={isExistPath}
            variant="contained"
            onClick={() =>
              currentStep === 3 ? handleCreatePage() : setCurrentStep(prev => prev + 1)
            }
            color="primary"
          >
            {currentStep < 3 ? 'Next step' : isEditMode ? 'Save' : 'Create'}
          </Button>
        </div>
      </DialogActions>
    </MUIDialog>
  );
};

export default memo(LandingPageManagementDialog);
