import { AiOutlineSearch } from 'react-icons/ai';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { HeaderWrapper, SelectItem, IconInputWrapper } from 'styles/global-styles';

const Header = ({ handleSearch, searchValue, handleFilterSelect, filterOptions }) => {
  return (
    <HeaderWrapper>
      <SelectItem>
        <IconInputWrapper direction="left">
          <AiOutlineSearch className="input--icon" color="#BBC0CE" size={16} />
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            onChange={handleSearch}
            value={searchValue}
            InputProps={{
              style: {
                paddingLeft: '20px',
                borderRadius: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
              },
            }}
          />
        </IconInputWrapper>
      </SelectItem>
      <Select
        className="select_size__sm"
        fullWidth
        value={filterOptions}
        onChange={e => handleFilterSelect(e.target.value)}
      >
        <MenuItem value="none">{'None'}</MenuItem>
        <MenuItem value="with_dead_link">With dead link</MenuItem>
      </Select>
    </HeaderWrapper>
  );
};

export default Header;
