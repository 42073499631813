const convertCapitalize = (str = '', uppercase) => {
  if (typeof str !== 'string') return str;

  if (uppercase) return str?.toUpperCase();

  return str
    ?.split(' ')
    .map(_word => _word.charAt(0).toUpperCase() + _word.slice(1, str.length))
    .join(' ');
};

export default convertCapitalize;
