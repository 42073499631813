import { memo } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

const InputField = ({ title, inputProps, value, style, boxTitle, handleChange }) => (
  <div>
    <div className="input-title">
      <span>*</span>
      {title}
    </div>
    <div className={clsx('input-field', { flex: boxTitle })} style={style}>
      {boxTitle && <div className="box">{boxTitle}</div>}
      <TextField
        fullWidth
        value={value}
        variant="outlined"
        onChange={handleChange}
        {...inputProps}
      />
    </div>
  </div>
);

export default memo(InputField);
