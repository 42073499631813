import { useState, useCallback, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import Paper from '@material-ui/core/Paper';
import MUITable from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';

const renderItem = (row, column, item, rowIndex) => {
  switch (column.type) {
    case 'link':
      const path = row.routes[column.id];

      return item ? <Link to={path}>{item}</Link> : '-';
    case 'renderComponent':
      const { Component } = column;
      return <Component row={row} column={column} item={item} index={rowIndex} />;
    default:
      return item;
  }
};

const perPageOptions = {
  10: [10, 25, 100],
  20: [20, 40, 60, 80, 100],
};

function Table({
  rows,
  columns,
  quantity,
  needToResetPage,
  defaultLimit = 10,
  needToPaginate = false,
  handleSortClick = noop,
  tableHeaderStyles = {},
  handleChangePage = noop,
  setNeedToResetPage = noop,
  paginationTitle = 'Items per page',
}) {
  const [page, setPage] = useState(0);
  const [sortableColumns, setSortableColumns] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (needToResetPage) {
      setPage(0);
      setNeedToResetPage(false);
    }
  }, [needToResetPage, setNeedToResetPage]);

  const handleChangeRowsPerPage = event => {
    const newRowPerPage = +event.target.value;
    setRowsPerPage(newRowPerPage);
    handleChangePage?.(0, newRowPerPage);
    setPage(0);
  };

  const onChangePage = useCallback(
    (_, newPage) => {
      const [orderBy, orderDir] = Object.entries(sortableColumns).flat();

      setPage(newPage);
      handleChangePage(newPage, rowsPerPage, orderBy, orderDir);
    },
    [handleChangePage, rowsPerPage, sortableColumns],
  );

  const rowData = needToPaginate
    ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows;

  const onSortClick = async (orderBy, orderDir) => {
    const currentDir = orderDir === 'desc' ? 'asc' : 'desc';
    await handleSortClick({ orderBy, rowsPerPage, orderDir: currentDir });
    setPage(0);
    setSortableColumns({ [orderBy]: orderDir === 'desc' ? 'asc' : 'desc' });
  };

  return (
    <TableContainer>
      <MUITable>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, fontWeight: 'bold', ...tableHeaderStyles }}
              >
                {column.label}
                {column.sortable && (
                  <TableSortLabel
                    active
                    onClick={() => onSortClick(column.orderBy, sortableColumns[column.orderBy])}
                    direction={sortableColumns[column.orderBy]}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((row, rowIndex) => (
            <TableRow hover tabIndex={-1} key={row.id} style={row.style}>
              {columns?.map(column => (
                <TableCell key={column.id} align={column.align} style={column.rowStyle}>
                  {renderItem(row, column, row[column.id], rowIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      {quantity > defaultLimit && (
        <TablePagination
          page={page}
          component={Paper}
          title={paginationTitle}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          count={quantity || rows.length}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={perPageOptions[defaultLimit]}
        />
      )}
    </TableContainer>
  );
}

export default memo(Table);
