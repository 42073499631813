import convertCapitalize from './convertCapitalize';

const meta = {
  '/404': {
    title: '404 - Niet gevonden... ',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/failed-payment': {
    title: 'Failed payment',
    description: 'Failed payment',
  },
  '/friends-of': {
    title: 'Friends Of',
    description: 'Friends Of',
  },
  '/login': {
    title: 'Inloggen',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/success-payment': {
    title: 'Success payment',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/sign-up-testpanel': {
    title: 'Signup testpanel',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/reset-password': {
    title: 'Reset Password',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/safety-information': {
    title: 'Veiligheid en Voorlichting',
    description:
      'Sexjobs probeert iedereen zo goed mogelijk te informeren hoe je veilig kan werken en afspreken in de prostitutie.',
  },
  '/promotion': {
    title: 'Promotie mogelijkheden op Sexjobs.nl',
    description:
      'De promotie mogelijkheden op Sexjobs. Voor iedere doelgroep zijn er andere mogelijkheden. Kom kijken welke promotie mogelijkheid bij jou past!',
  },
  '/code-of-conduct': {
    title: 'Code Of Conduct',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/privacy-policy': {
    title: 'Privacy policy | Sexjobs.nl',
    description: 'Privacy policy van Sexjobs.nl',
  },
  '/advertentie/new': {
    title: 'Inloggen',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/dames-van-plezier/aftreksex': {
    title: 'Aftreksex door geile dames van plezier via Sexjobs.nl',
    description:
      'Aftrek advertenties van nieuwsgierige geile meiden en vrouwen die mannen willen aftrekken tegen een kleine vergoeding.',
  },
  '/dames-van-plezier/anaal': {
    title: 'Anaal genomen worden vinden deze dames van plezier heerlijk',
    description:
      'Anale sex advertenties aangeboden door geile dames van plezier. Op Sexjobs hebben wij het grootste aanbod dames van plezier die anaal geneukt willen worden. ',
  },
  '/dames-van-plezier/bbw': {
    title: 'BBW nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil jij een BBW (Big Beautiful Woman) neuken? Bekijk hier ons grootste en zwaarste aanbod amateur BBW dames. Beschikbaar voor thuisontvangst en escort!',
  },
  '/dames-van-plezier/buitenlandse-dames': {
    title: 'Buitenlandse dames van plezier via Sexjobs.nl',
    description:
      'Buitenlandse dames Advertenties. Het zijn Aziatische, Poolse, Roemeense, Russische, Braziliaanse, Afrikaanse, etc dames die in Nederland op zoek zijn naar een sexafspraak. Op Sexjobs hebben wij het grootste aanbod buitenlandse dames van plezier. Kom nu jouw keuze maken!',
  },
  '/dames-van-plezier/cardate': {
    title: 'Cardate plannen? Kijk snel op Sexjobs.nl',
    description:
      'Wil jij een hoer neuken tijdens een cardate? Bekijk hier ons grootste aanbod van cardate advertenties. Deze hoeren houden ook van een vluggertje.',
  },
  '/dames-van-plezier/deepthroat': {
    title: 'Deepthroat bij de geilste dames van plezier via Sexjobs.nl',
    description:
      'Deepthroat advertenties van hoertjes die gekeelneukt willen worden. Op Sexjobs hebben wij het grootste aanbod dames van plezier die houden van deepthroat pijpen. Wordt ook wel gaggen of facefucking genoemd. Hoe dieper, hoe harder... hoe geiler! Kom nu met één van deze fuckfaces een afspraak maken!',
  },
  '/dames-van-plezier/dinnerdates': {
    title: 'Dinnerdates met sexy dames van plezier via Sexjobs.nl',
    description:
      'Dinnerdate advertenties van dames van plezier. Via Sexjobs heb je overzichtelijk het grootste aanbod dames van plezier die een dinnerdate met jou wel zien zitten. Samen uit, samen eten, samen sex? Kom nu een afspraak scoren!',
  },
  '/dames-van-plezier/escort': {
    title: 'Escort dame nodig? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een geile escort? Om te neuken, voor een erotische massage of een gezellige dinnerdate? Bekijk hier de meest komplete lijst met escort dames.',
  },
  '/dames-van-plezier/exotische-vrouwen': {
    title: 'Exotische vrouwen en dames van plezier via Sexjobs.nl',
    description:
      "Exotische vrouwen advertenties op Sexjobs. Hier vind je zwarte vrouwen, ebony's, getinte vrouwen, vrouwen met een kleurtje. Ze zijn op zoek naar sekscontact. Onze premium lijst bevat exotische dames van plezier. Surinaams, Hindoestaans tot aan Braziliaans. Allemaal exotisch en geil!",
  },
  '/dames-van-plezier/girlfriend-experience': {
    title: 'Girlfriend experience beleven? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een girlfriend experience? Wil je meer dan simpel aftreksex of een amateur neuken. Deze super sexy meiden gaan voor een echte GFE!',
  },
  '/dames-van-plezier/grote-tieten': {
    title: 'Gek op grote tieten? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een dame met grote tieten? Bekijk hier ons grootste aanbod grote tieten advertenties. Hete geile dames voor thuisontvangst  en of escort.',
  },
  '/dames-van-plezier/hotel-escort--ontvangst': {
    title: 'Hotel escort of ontvangst nodig? Kijk snel op Sexjobs.nl',
    description:
      'Zoek je een hotel escort of een dame die daar ontvangt? Bekijk hier ons grootste aanbod hotel escort en ontvangst advertenties. Gewoon lekker neuken.',
  },
  '/dames-van-plezier/koppels': {
    title: 'Met een sex koppel afspreken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een heerlijk sex koppel voor een trio? Bekijk de exclusieve lijst van advertenties met sex koppels die graag een sex date trio willen met jou.',
  },
  '/dames-van-plezier/neuken-zonder-condoom': {
    title: 'Wil je Neuken zonder condoom? Kijk snel op Sexjobs.nl',
    description:
      'Wil jij neuken zonder condoom? Bekijk hier ons grootste aanbod neuken zonder condoom advertenties. Amateur hoeren vinden nzc super geil en spannend!',
  },
  '/dames-van-plezier/pijpen-zonder-condoom': {
    title: 'Wil je gepijpt worden zonder condoom? Kijk snel op Sexjobs.nl',
    description:
      'Hoeren die pijpen zonder condoom? Bekijk hier ons grootste aanbod pijpen zonder condoom advertenties. Deze hoeren zuigen jouw harde pik helemaal leeg!',
  },
  '/dames-van-plezier/rijpe-vrouwen': {
    title: 'Rijpe vrouw neuken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar rijpe vrouwen om te neuken? Bekijk hier ons grootste aanbod rijpe vrouwen advertenties. Amateur hoeren voor thuisontvangst en escort!',
  },
  '/dames-van-plezier/sexbedrijven': {
    title: 'Club of Privehuis bezoeken? Kijk snel op Sexjobs.nl',
    description:
      'Wil je een club of privehuis bezoeken? Regelmatig nieuwe dames aanwezig. Ook voor een trio, gangbang, strapon sex en rijpe dames kan je hier vaak terecht!',
  },
  '/dames-van-plezier/squirten': {
    title: 'Squirten door dames van plezier via Sexjobs.nl',
    description:
      'Squirt advertenties van geile hoertjes. Op Sexjobs vind je het grootste aanbod dames van plezier die heerlijk kunnen squirten en graag jouw gezicht of harde pik onder sproeien.',
  },
  '/dames-van-plezier/strapon-sex': {
    title: 'Strapon sex door geile dames van plezier via Sexjobs.nl',
    description:
      'Strapon sex advertenties. Op Sexjobs hebben wij het grootste aanbod dames van plezier die van strapon sex houden en jou graag anaaltraining geven met hun voorbinddildo! Deze hoertjes zoeken mannen die als een slet geneukt willen worden met een strapon.',
  },
  '/dames-van-plezier/studentes': {
    title: 'Studentes neuken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar studentes voor een sex date? Bekijk hier ons grootste aanbod studentes die houden van neuken, vluggertjes, squirten maar ook van dinnerdates.',
  },
  '/dames-van-plezier/suikeroom-gevraagd': {
    title: 'Ben jij een Suikeroom? Kijk snel op Sexjobs.nl',
    description:
      'Wil jij iemands suikeroom zijn? Bekijk hier ons grootste aanbod suikeroom gevraagd advertenties. Er wordt regelmatig gevraagd om een gulle suikeroom.',
  },
  '/dames-van-plezier/thuisontvangst': {
    title: 'Dames die aan thuisontvanst doen! Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een dame van plezier die aan thuisontvangst doet? Bekijk ons grootste aanbod thuisontvangst advertenties. Ook amateurs doen thuisontvangst!',
  },
  '/dames-van-plezier/trio-met-2-dames': {
    title: 'Wil jij een trio met 2 dames? Kijk snel op Sexjobs.nl',
    description:
      'Droom jij van een trio met 2 dames? Bekijk het grootste aanbod trio met 2 dames advertenties. Twee sexy vriendinnen of juist een geile moeder en dochter.',
  },
  '/dames-van-plezier/vluggertjes': {
    title: 'Vluggertjes met dames van plezier via Sexjobs.nl',
    description:
      'Vluggertjes advertenties van dames die beschikbaar zijn voor een snelle sexdate. Wij hebben voor jou het grootste aanbod dames van plezier die geilen op een vluggertje samen met jou.',
  },
  '/dames-van-plezier/voetlovers': {
    title: 'Ben jij een voetlover? Kijk snel op Sexjobs.nl',
    description:
      'Is voetlover jouw fetish? Bekijk hier ons grootste aanbod van aangeboden voeten advertenties voor voetlovers. Dit zijn dames met voetjes om te verwennen.',
  },
  '/gangbang/algemeen': {
    title: 'Meedoen met een gangbang? Kijk snel op Sexjobs.nl',
    description:
      'Wil je meedoen met een Gangbang? Bekijk hier het grootste aanbod gangbang party advertenties. De gangbang agenda is altijd up to date!',
  },
  '/gangbang/barebangs': {
    title: 'Meedoen met een barebang? Kijk snel op Sexjobs.nl',
    description:
      'Wil je mee doen met een Barebang? Bekijk hier het grootste aanbod met bare gangbang party advertenties. De bare gangbang agenda is altijd up to date!',
  },
  '/gangbang/erotisch-uitgaan': {
    title: 'Wil je erotisch uitgaan? Kijk snel op Sexjobs.nl',
    description:
      'Wil je Erotisch uitgaan? Bekijk hier het grootste aanbod van erotisch uitgaan advertenties. De lijst wordt regelmatig aangevuld met nieuwe uitgaan tips!',
  },
  '/erotische-massage/body-to-body-massage': {
    title: 'Body to body massage nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je een erotische Body to Body massage? Bekijk hier het grootste aanbod met b2b massage advertenties. Ook voor een B2B massage met grote tieten.',
  },
  '/erotische-massage/massage-door-paren': {
    title: 'Massage van een stelletje nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je een erotische massage door een stelletje? Bekijk hier het aanbod met massage door paren advertenties. Stel is nu beschikbaar voor erotische massage.',
  },
  '/erotische-massage/massagesalon': {
    title: 'Ben jij opzoek naar een heerlijke erotische massagesalon?',
    description:
      'Wij hebben een totaal overzicht van erotische massagesalons die gespecialiseerd zijn in tantra, prostaat en Nuru massages. 100% hygiënisch.',
  },
  '/erotische-massage/prostaat-massage': {
    title: 'Prostaat massage nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je een Prostaat massage? Bekijk hier het grooste aanbod van prostaat massage advertenties. Laat nu je mannelijke g-spot heerlijk verwennen!',
  },
  '/erotische-massage/tantra-massage': {
    title: 'Tantra massage nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je een erotische Tantra massage? Bkijk hier het grootste aanbod van tantra massage advertenties. Tantra massage is intenser dan een erotische massage!',
  },
  '/bdsm/dominant': {
    title: 'Ben je opzoek naar een dominante strenge meesteres?',
    description:
      'Bekijk ons aanbod van de meest extreem dominante meesteressen die opzoek zijn naar slaven zoals jij om te domineren',
  },
  '/bdsm/geldslaven': {
    title: 'Ben jij als Geldslaaf opzoek naar een Meesteres?',
    description:
      'De meest ambitieuze meesteressen zijn opzoek naar echte geldslaven. Durf jij je te onderwerpen aan deze dominerende meesteressen?',
  },
  '/bdsm/kinky-extreem--bizar': {
    title: 'Kinky, extreem en bizarre advertenties! Kijk op Sexjobs.nl',
    description:
      'Op zoek naar iets Kinky, Extreem of Bizar? Bekijk hier het grootste aanbod van sm kinky, extrreem en bizarre advertenties. Voor en door bizarre mensen!',
  },
  '/bdsm/onderdanig': {
    title: 'Ben je opzoek naar een Meesteres of ervaren Domina?',
    description:
      "Bekijk advertenties van top Meesteres en Domina's. Kies de meesteres of domina die opzoek zijn naar onderdaninge subjes die zich onderwerpen.",
  },
  '/bdsm/switch': {
    title: 'Switch nodig? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een Switch afspraak? Bekijk hier het grootste aanbod van sm switch advertenties. Vind je Kinky playmate, travestie slavin of switch begeleider!',
  },
  '/cam-en-telefoonsex/online-femdom': {
    title: 'Zin in Online femdom? Kijk snel op Sexjobs.nl',
    description:
      'Zin in Online Femdom? Bekijk hier het grootste aanbod van Online femdom advertenties. Hier vind jij jouw nieuwe online meesteres!',
  },
  '/cam-en-telefoonsex/telefoonsex': {
    title: 'Zin in telefoonsex? Kijk snel op Sexjobs.nl',
    description:
      'Zin in Telefoonsex? Bekijk hier het grootste aanbod van telefoonsex advertenties. Kom voor beeldbellen, aftrekinstructies of een erotisch telefoongesprek!',
  },
  '/cam-en-telefoonsex/webcamsex': {
    title: 'Ben jij opzoek naar online Webcamsex?',
    description:
      'Webcamsex heb je snel via onze meest geile adverteerders. Bekijk de meest complete lijst van vrouwen die met jouw Webcamsex willen hebben.',
  },
  '/cam-en-telefoonsex/whatsapp-sms--kik': {
    title: 'Afspreken via whatsapp, sms of kik? Kijk snel op Sexjobs.nl',
    description:
      'Zin in sex via Whatsapp, SMS of Kik? Bekijk hier het grootste aanbod van Whatsapp, SMS en Kik advertenties. Hier vind je zeker een geile dame voor jezelf!',
  },
  '/mannen/betaald-contact-gezocht': {
    title: 'Betaald contact gezocht? Kijk snel op Sexjobs.nl',
    description:
      'Zoek jij mannen die jou betalen? Bekijk het grootste aanbod van betaald contact gezocht advertenties. Deze mannen zoeken dames van plezier en betalen jou!',
  },
  '/mannen/erotische-massage': {
    title: 'Massage door een man nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je een erotisch massage door een man? Bekijk hier het grootste aanbod massage door heren advertenties. Direct beschikbaar en klaar om af te spreken.',
  },
  '/mannen/escort': {
    title: 'Escort heren bieden zich aan via Sexjobs.nl',
    description:
      'Escort heren advertenties. Op Sexjobs vind je het grootste aanbod van heren die zichzelf aanbieden als escort. Deze geile mannen willen jou graag de tijd van je leven bezorgen. Ben je op zoek naar een stoere gigolo of een echt bef koning? Je vind ze allemaal op onze site!',
  },
  '/mannen/priv-ontvangst': {
    title: 'Sexjobs - De Erotische Marktplaats',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/mannen/shemale': {
    title: 'Shemales die zich aanbieden via Sexjobs.nl',
    description:
      'Shemale advertenties. Wij hebben op Sexjobs het grootste aanbod van transseksuelen en shemales die zich aanbieden voor betaalde dates!',
  },
  '/mannen/sm': {
    title: 'Sexjobs - De Erotische Marktplaats',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/mannen/suikeroom': {
    title: 'Suikeroom nodig? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar een Suikeroom? Bekijk hier het grootste aanbod van suikeroom aangeboden advertenties. Suikerooms die financiële hulp aanbieden!',
  },
  '/betaling-in-natura/automonteurs': {
    title: 'Automonteur nodig? Kijk snel op Sexjobs.nl',
    description:
      'Automonteurs advertenties. Op Sexjobs vind je het grootste aanbod van automonteurs gezocht of aangeboden. Heb jij schade aan je auto of een reparatie nodig? Laat het repareren door een automonteur en betaal in natura. Maar je kan jezelf ook aanbieden als automonteur en in natura betaald worden!',
  },
  '/betaling-in-natura/autorijles': {
    title: 'Autorijles nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je autorijlessen volgen? Bekijk hier het grootste aanbod autorijles advertenties die betaling in natura aanbieden! Geld speelt hier geen rol.',
  },
  '/betaling-in-natura/kappers': {
    title: 'Kapper nodig? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een kapper of wil je juist een knipbeurt geven? Bekijk hier het grootste aanbod kappers advertenties die betaling in natura aanbieden.',
  },
  '/betaling-in-natura/klusjes-en-onderhoud': {
    title: 'Klusjesman nodig voor onderhoud? Kijk snel op Sexjobs.nl',
    description:
      'Klusjesman aangeboden of op zoek naar een klusjesman? Bekijk hier het grootste aanbod klusjes en onderhoud advertenties die betaling in natura aanbieden.',
  },
  '/gratis-sexcontact/buitensex': {
    title: 'Buitensex afspraak maken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar buitensex? Buitensex gevraagd of aangeboden, bekijk hier het grootste aanbod buitensex advertenties. Durf jij het avontuur aan te gaan?',
  },
  '/gratis-sexcontact/cuckolding': {
    title: 'Cuckolding afspraak maken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een Cuckholding date? Cuckholding gevraagd of aangeboden, bekijk hier het grootste aanbod cuckholding advertenties en maak je keuze!',
  },
  '/gratis-sexcontact/cum-tributes': {
    title: 'Cum tributes op Sexjobs.nl',
    description:
      "Cum tributes advertenties. Klaarkomen over foto's! Vind jij het lekker om je af te trekken op foto's en video's van anderen en dat te reposten? Hier op Sexjobs vind je het grootste aanbod van beroemde Cum tributes. Bekijk of plaats je eigen Cum tribute!",
  },
  '/gratis-sexcontact/dominant': {
    title: 'Dominant aangeboden messter(essen) via Sexjobs.nl',
    description:
      'Dominant aangeboden advertenties. Op Sexjobs vind je het grootste aanbod van dominante meester(essen) die zich gratis aanbieden. Geef je over aan de opdrachten van een strenge meester of meesteres die jou dingen laat. Jij bent de onderdanige slaaf die je altijd al had willen zijn!',
  },
  '/gratis-sexcontact/exhibitionisme': {
    title: 'Exhibitionisme met spannend sexcontact via Sexjobs.nl',
    description:
      'Exhibitionisme advertenties. Je vind op Sexjobs het grootste aanbod van alles wat met exhibitionisme te maken heeft zolang het maar niet commercieel is. Super geil wanneer je bekeken wilt worden terwijl je sex hebt of jezelf masturbeert.',
  },
  '/gratis-sexcontact/gehandicapten': {
    title: 'Gehandicapten voor speciaal sexcontact via Sexjobs.nl',
    description:
      'Gehandicapten sexcontact advertenties. Hier op Sexjobs vind je het meest volledige vraag en aanbod van en naar sexcontact met gehandicapten. Want ook met een handicap kan er heerlijk genoten worden van sex. Heb jij al zin? Kom dan nu langs!',
  },
  '/gratis-sexcontact/kleurlingen': {
    title: 'Ben of heb je een kleurling nodig? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar kleurlingen die sex aanbieden of vragen? Groot aanbod van kleurlingen, turks, marokkaans en arabische advertenties. Dat wordt lekker neuken!',
  },
  '/gratis-sexcontact/man-zoekt-vrouw': {
    title: 'Op zoek naar een man? Kijk snel op Sexjobs.nl',
    description:
      'Als vrouw op zoek naar een man? Bekijk hier het aanbod van man zoekt vrouw advertenties. Dagelijks nieuwe mannen die op zoek zijn naar een vrouw!',
  },
  '/gratis-sexcontact/parenclub': {
    title: 'Parenclub sexcontact via Sexjobs.nl',
    description:
      'Parenclub advertenties. Op Sexjobs hebben wij het meest complete overzicht van gratis vraag en aanbod van contacten om naar een parenclub te gaan zolang het niet commercieel is. Zoek hier iemand uit die met jou naar een parenclub wilt gaan. Have Fun!',
  },
  '/gratis-sexcontact/plas--poep': {
    title: 'Plas of Poep afspraak maken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar plas en of poep contacten? Bekijk hier het grootste aanbod van plas en poep sex advertenties. Zeer speciaal contact maar dan net iets extremer!',
  },
  '/gratis-sexcontact/stel-zoekt-stel': {
    title: 'Als stel op zoek naar een stel? Kijk snel op Sexjobs.nl',
    description:
      'Zijn jullie op zoek naar een stel? Bekijk hier het grootste aanbod van stel zoekt stel advertenties. Deze stellen zoeken een ander stel voor sex en meer.',
  },
  '/gratis-sexcontact/stel-zoekt-vrouw': {
    title: 'Als vrouw op zoek naar een stel? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar een stel? Bekijk het grootste aanbod van stel zoekt vrouw advertenties. Deze stellen zoeken een vrouw voor sex trio en gezelligheid.',
  },
  '/gratis-sexcontact/travestie': {
    title: 'Travestie gezocht voor gratis sexcontact via Sexjobs.nl',
    description:
      'Travestie advertenties. Wij hebben op Sexjobs het grootste vraag en aanbod van sexcontacten met travestieten, transseksuelen en shemales. Heb je zin in een vrouw met een verrassing of gewoon eens zin in een speciaal seks avontuurtje, kom dan snel langs!',
  },
  '/gratis-sexcontact/vakantie': {
    title: 'Vakantie partner nodig? Kijk snel op Sexjobs.nl',
    description:
      'Wil je op vakantie of zoek je een vakantiepartner? Bekijk hier het grootste aanbod sex vakantie advertenties die betaling in natura aanbieden.',
  },
  '/gratis-sexcontact/voetlovers': {
    title: 'Jouw voeten aanbieden? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar Voetlovers of zoek je voeten om aan te likken? Bekijk hier het grootste aanbod van voetlovers advertenties. Alleen voor de voetlover fetish! ',
  },
  '/gratis-sexcontact/vrouw-zoekt-stel': {
    title: 'Als stel op zoek naar een vrouw? Kijk snel op Sexjobs.nl',
    description:
      'Zijn jullie op zoek naar een Vrouw voor een geil sex trio? Bekijk hier het grootste aanbod van vrouw zoekt stel advertenties. Dagelijks nieuwe stelletjes.',
  },
  '/gratis-sexcontact/wap-dates': {
    title: 'Op zoek naar een Wap date? Kijk snel op Sexjobs.nl',
    description:
      'Wap dates speciaal voor jou geselecteerd! Bekijk het grootste aanbod van wap dates advertenties. Ben jij een leuke meid kom dan direct kijken!',
  },
  '/erotische-vacatures/dames-gevraagd': {
    title: 'Dames gevraagd vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar werk als Dame van plezier? Bekijk hier het grootste aanbod van dames gevraagd advertenties. Je kan meestal snel aan de slag!',
  },
  '/erotische-vacatures/fotografen': {
    title: 'Fotografen vacatures! Kijk snel op Sexjobs.nl',
    description:
      "Fotografen gezocht of aangeboden? Bekijk hier het aanbod van fotografen advertenties. Professioneel, discreet voor mooie erotische foto's en of video's.",
  },
  '/erotische-vacatures/gangbang-werk': {
    title: 'Gangbang werk vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met Gangbang werk? Bekijk hier het grootste aanbod van gangbang werk advertenties. Regelmatig nieuwe vacatures online.',
  },
  '/erotische-vacatures/gastvrouwen': {
    title: 'Gastvrouwen gevraagd voor sexclubs via Sexjobs.nl',
    description:
      'Gastvrouwen gevraagd advertenties. Op Sexjobs hebben wij het grootste aanbod van vacatures voor gastvrouwen. Hier vind je uitsluitend advertenties van vergunde clubs en privéhuizen.',
  },
  '/erotische-vacatures/horeca-personeel': {
    title: 'Horeca personeel gevraagd of aangeboden via Sexjobs.nl',
    description:
      'Horeca personeel advertenties. Via Sexjobs vind je het grootste aanbod van bedrijfsleiders, bardames, gastvrouwen en hostesses en andere advertenties die aan de horeca gerelateerd zijn. Van naakte butler tot sexy barvrouw. Je vind het allemaal.',
  },
  '/erotische-vacatures/kantoor-personeel': {
    title: 'Kantoor personeel gevraagd en aangeboden via Sexjobs.nl',
    description:
      'Kantoor personeel advertenties. Op zoek naar een sexy administratief medewerkster, of ben jij op zoek naar een baan op kantoor met net dat beetje extra? Maar ook voor serieuze kantoorbanen hebben wij op Sexjobs het grootste aanbod. Werk jezelf langzaamaan omhoog!',
  },
  '/erotische-vacatures/mail--chat-werk': {
    title: 'Mail of chat werk vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met Mail of Chatwerk? Bekijk hier het grootste aanbod van mail en chat werk advertenties. Regelmatig nieuwe vacatures online.',
  },
  '/erotische-vacatures/massage-vacatures': {
    title: 'Erotische massage vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met het geven van erotische massages? Bekijk hier het grootste aanbod massage vacature advertenties. Regelmatig nieuwe vacatures!',
  },
  '/erotische-vacatures/personal-assistant': {
    title: 'Personal assistant gezocht via Sexjobs.nl',
    description:
      'Personal assistant advertenties. Op Sexjobs vind je het grootste vraag en aanbod van PA of ook wel Personal Assistants die onder andere privé werkzaamheden verrichten en mee willen reizen. ',
  },
  '/erotische-vacatures/schoonmakers': {
    title: 'Schoonmaaktster vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met erotisch schoonmaken? Bekijk hier het grootste aanbod van schoonmakers vacatures. Regelmatig nieuwe vacatures!',
  },
  '/erotische-vacatures/thuiswerk': {
    title: 'Thuiswerkt vacatures! Kijk snep op Sexjobs.nl',
    description:
      'Wil je geld verdienen met erotisch thuiswerk? Bekijk hier het grootste aanbod van erotisch thuiswerk vacatures. Regelmatig nieuwe vacatures online.',
  },
  '/erotische-vacatures/webcam-werk': {
    title: 'Webcam werk vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met webcam werk? Bekijk hier het aanbod van erotisch webcam werk vacatures. Er zijn verschillende aanbieders met goede verdiensten.',
  },
  '/erotische-vacatures/winkelpersoneel': {
    title: 'Winkelpersoneel gevraagd en aangeboden via Sexjobs.nl',
    description:
      'Winkelpersoneel advertenties. Je vind op Sexjobs het grootste vraag en aanbod van erotische vactures voor winkelpersoneel. Het kan als een leuke bijverdienste werken maar ook full time mogelijkheden worden getoond. Kom zelf kijken of er wat tussen zit voor jou!',
  },
  '/sexartikelen-en-diensten/condooms': {
    title: 'Volgespoten condooms gevraagd of aangeboden via Sexjobs.nl',
    description:
      'Volgespoten condooms advertenties. Hier op Sexjobs vind je het grootste vraag en aanbod volgespoten condooms. Verkoop jij je eigen volgespoten condooms? Of ben je juist op zoek naar een warme volgespoten condoom! Hier vind je werkelijk alle sperma artikelen op één plaats!',
  },
  '/sexartikelen-en-diensten-en-diensten/fotos-dvds-en-videos': {
    title: "Op zoek naar foto's, dvd of video's? Kijk snel op Sexjobs.nl",
    description:
      "Ben jij op zoek naar geile foto's, dvd's of juist video's? Bekijk hier het grootste aanbod van sex foto, dvd en video advertenties. Er is veel mogelijk!",
  },
  '/sexartikelen-en-diensten/sexpoppen': {
    title: 'Sexartikelen aangeboden via Sexjobs.nl',
    description:
      'Sexartikelen aangeboden advertenties. Hier op Sexjobs heb je Het grootste aanbod van sexartikelen. Voor nieuwe, maar vooral ook gebruikte, sexartikelen ben je bij ons op de juiste plek. Enkel en alleen aangeboden door particulieren! Kom snel iets uitzoeken.',
  },
  '/sexartikelen-en-diensten/sextoys': {
    title: 'Sexartikelen gevraagd via Sexjobs.nl',
    description:
      'Sexartikelen gevraagd advertenties. Op zoek naar bepaalde sexartikelen? Plaats hier je oproep. Op zoek naar speciale seksartikelen. Gebruikte slipjes, sexspeeltjes of een andere sexartikel dan kan je deze hier je oproep plaatsen en kan je zeker een reactie verwachten!',
  },
  '/diversen/overnames': {
    title: 'Overnames via Sexjobs.nl',
    description:
      'Overnames advertenties. Zie jij het wel zitten om een sexclub over te nemen? Op sexjobs vind je het grootste aanbod van sexclubs die op zoek zijn naar een nieuwe eigenaar. Maar ook alle andere overige over te nemen advertenties kan je hier vinden zolang ze maar erotiek gerelateerd zijn.',
  },
  '/diversen/sexbioscopen': {
    title: 'Op zoek naar een sexbioscoop? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar een sexbioscoop? Bekijk hier het grootste aanbod van sexbioscopen advertenties die je door heel Nederland kan vinden.',
  },
  '/diversen/striptease': {
    title: 'Striptease nodig of geven? Kijk snel op Sexjobs.nl',
    description:
      'Striptease advertenties. Sexjobs heeft het grootste aanbod van vrouwlijke en mannelijke strippers die hun diensten betaald aanbieden. Maar je kan hier ook terecht wanneer je een striptease-act wilt huren of op zoek bent naar strippers. Kom zelf maar eens kijken!',
  },
  '/diversen/webshops--groothandel': {
    title: 'Webshop en groothandel advertenties via Sexjobs.nl',
    description:
      'Webshops en groothandel advertenties. Op Sexjobs hebben wij het grootste aanbod van erotische shops en groothandels. Je vind hier advertenties van verschillende webshops. Heb jij een seksspeeltje nodig of ben je op zoek naar pillen voor de seks?',
  },
  '/diversen/websites-en-design': {
    title: 'Een website of design nodig? Kijk snel op Sexjobs.nl',
    description:
      'Een erotische website of design nodig? Bekijk hier het grootste aanbod van websites en design advertenties te vinden door heel Nederland.',
  },
  '/gay-en-bi/betaling-in-natura': {
    title: 'Gay en Bi betaling in natura',
    description:
      'Op zoek naar een gay of bi die aan betaling in natura doet? Bekijk ons grootste aanbod gay en bi betaling in natura advertenties.',
  },
  '/gay-en-bi/bottom': {
    title: 'Gay en Bi bottom',
    description:
      'Op zoek naar een gay of bi die aan bottom sex doet? Bekijk ons grootste aanbod gay en bi bottom sex advertenties.',
  },
  '/gay-en-bi/gangbang': {
    title: 'Gay en Bi gangbang',
    description:
      'Op zoek naar een gay of bi die aan gangbang doet? Bekijk ons grootste aanbod gay en bi gangbang  advertenties.',
  },
  '/gay-en-bi/gratis-sexcontact-label': {
    title: 'Gratis gay 0f bi sexcontact nodig? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar gratis sexcontact met een gay of een bi? Bekijk hier het grootste aanbod van gratis gay en bi sexcontact advertenties. Wat zoek jij?',
  },
  '/gay-en-bi/top': {
    title: 'Gay en Bi on top',
    description:
      'Op zoek naar een gay of bi die aan on top sex doet? Bekijk ons grootste aanbod gay en bi on top sex advertenties.',
  },
  '/dames-van-plezier': {
    title: 'Dame van plezier nodig?  Kijk snel op Sexjobs.nl',
    description:
      'Wil jij een dame van plezier neuken? Het grootste aanbod Thuisontvangst en Escort advertenties. Top advertenties zijn direct beschikbaar voor een sex date!',
  },
  '/dames-van-plezier/buitensex': {
    title: 'Buitensex met geile dames van plezier via Sexjobs.nl',
    description:
      'Buitensex advertenties. Weer of geen weer het is altijd buitensex weer. Op Sexjobs hebben wij het grootste aanbod dames van plezier die gek zijn op sex',
  },
  '/gangbang': {
    title: 'Gangbang evenement bezoeken? Kijk snel op Sexjobs.nl',
    description:
      'Hardcore evenementen overzicht voor een gangbang, barebang, bukkake of  erotisch uitgaan vind je exclusief op Sexjobs.nl. Geef je op en doe mee!',
  },
  '/sm': {
    title: 'SM advertenties lezen? Kijk snel op Sexjobs.nl',
    description:
      'Kompleet SM overzicht. Van dominant, onderdanig tot kinky,extreem en bizar. Geen meesteres te extreem en geen geldslaaf is te rijk. Iedereen is welkom!',
  },
  '/gratis-sexcontact/rollenspel': {
    title: 'Rollenspel maakt jouw droom werkelijkheid via Sexjobs.nl',
    description:
      'Rollenspel advertenties. Hier op Sesjobs vind je het grootste aanbod oproepen van mensen die een niet commercieel rollenspel willen spelen zoals dokter/patiënt, papa/dochter, politie/crimineel, ponyplay en natuurlijk nog veel meer. Kom hier je keuze maken!',
  },
  '/gratis-sexcontact/stel-zoekt-man': {
    title: 'Als man op zoek naar een stel? Kijk snel Sexjobs.nl',
    description:
      'Ben jij op zoek naar een stel? Bekijk hier het grooste aanbod van stel zoekt man advertenties. Deze stellen zoeken een man voor sex trio en gezelligheid.',
  },
  '/gratis-sexcontact/vrouw-zoekt-man': {
    title: 'Als man op zoek naar een vrouw? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een Vrouw voor geile seks? Bekijk hier het grootste aanbod van vrouw zoekt man advertenties. Deze amateur dames zijn geil en willen neuken!',
  },
  '/sexartikelen-en-diensten/gedragen-slipjes': {
    title: 'Op zoek naar gedragen slipjes? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar gedragen slipjes of kleding? Bekijk hier het grootste aanbod van gedragen slipjes en kleding advertenties. Heerlijke natte strings!',
  },
  '/sexartikelen-en-diensten/lingerie--kleding': {
    title: 'Op zoek naar laarzen of schoenen? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar gedragen laarzen en of schoenen? Bekijk het grootste aanbod van laarzen en schoenen advertenties. Regelmatig andere schoenen.',
  },
  '/diversen': {
    title: 'Diversen advertenties! Kijk snel op Sexjobs.nl',
    description:
      'Dit is de meest uitgebreide lijst van alles wat erotiek gerelateerd is maar nergens op Sexjobs.nl te plaatsen is. Kom zelf maar eens kijken en beoordelen!',
  },
  '/diversen/kamerverhuur': {
    title: 'Op zoek naar een kamer? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij als zelfstandige dame op zoek naar Kamerverhuur? Bekijk hier het grootste aanbod van kamerverhuur advertenties door heel Nederland.',
  },
  '/cam-en-telefoonsex': {
    title: 'Cam en Telefoonsex nodig? Kijk snel op Sexjobs.nl',
    description:
      'Cam en Telefoonsex advertenties. Bekijk het grootste aanbod telefoon sex, webcam , whatsapp en online femdom advertenties! Kom op en pak die telefoon!',
  },
  '/gratis-sexcontact': {
    title: 'Gratis sexcontact nodig? Kijk snel op Sexjobs.nl',
    description:
      'Gratis sexcontact advertenties. Heel veel mogelijkheden; van Buitensex, Cuckolding tot aan Sex zonder condoom, wap dates en voetlovers. Waar hou jij van?',
  },
  '/erotische-massage': {
    title: 'Erotische massage overzicht voor erotisch ontspanning via Sexjobs.nl',
    description:
      'Erotische massage advertenties. Op Sexjobs hebben wij het grootste aanbod van allerlei soorten erotische massages. Van body to body massage tot prostaat massage, maar ook de nu populaire Nuru massages. Er is voor iedereen wel een passende massage aanwezig!',
  },
  '/dames-van-plezier/wap-dates': {
    title: 'Wap dates met ondeugende dames van plezier via Sexjobs.nl',
    description:
      'Wap dates advertenties van vrouwen en koppels die zin hebben om lekker ongeremd te geilen. Wij hebben op Sexjobs het grootste aanbod dames van plezier die geil worden van wap dates.',
  },
  '/gratis-sexcontact/man-zoekt-stel': {
    title: 'Hebben jullie een extra man nodig? Kijk snel op Sexjobs.nl',
    description:
      'Als stel op zoek naar een man? Bekijk het grootste aanbod van man zoekt stel advertenties. Dagelijks nieuwe mannen op zoek naar stellen voor een trio.',
  },
  '/gratis-sexcontact/onderdanig': {
    title: 'Onderdanig aangeboden voor de liefhebbers via Sexjobs.nl',
    description:
      'Onderdanig aangeboden advertenties. Hier op Sexjobs vind je de meest complete lijst van slaven en slavinnen die zich gratis aanbieden. Kies hier op je gemak jouw slaaf uit en domineer deze slaaf naar hartenlust!',
  },
  '/gratis-sexcontact/sex-zonder-condoom': {
    title: 'Wil je als vrouw sex zonder condoom? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een man voor sex zonder condoom? Bekijk hier het aanbod sex zonder condoom advertenties. Kijk wanneer je bare geneukt wilt worden.',
  },
  '/gratis-sexcontact/vrouw-zoekt-vrouw': {
    title: 'Als vrouw op zoek naar een vrouw? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een Vrouw voor lesbische seks? Bekijk hier het grootste aanbod van vrouw zoekt vrouw advertenties. Deze vrouwen zijn geil en willen jouw kutje!',
  },
  '/erotische-vacatures/bel-werkzaamheden': {
    title: 'Bel werkzaamheden en vacatures via Sexjobs.nl',
    description:
      'Bel werkzaamheden advertenties. Wij hebben op Sexjobs het grootste aanbod van vraag en aanbod met betrekking tot 0906 nummers, erotische gesprekken en telefoon werkzaamheden. Fulltime beldames gezocht!',
  },
  '/erotische-vacatures/modellen': {
    title: 'Modellen vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen als erotisch model? Bekijk hier het grootste aanbod van modellen vacatures. Van soft erotisch model tot echte hardcore modellen werk.',
  },
  '/gay-en-bi/massage': {
    title: 'Gay en Bi massage',
    description:
      'Op zoek naar een gay of bi die aan erotische massage doet? Bekijk ons grootste aanbod gay en bi erotische massage advertenties.',
  },
  '/gay-en-bi/sexbedrijven': {
    title: 'Gay en Bi sexbedrijven',
    description:
      'Op zoek naar een gay of bi sexbedrijf? Bekijk ons grootste aanbod gay en bi sexbedrijven advertenties.',
  },
  '/gay-en-bi/sm': {
    title: 'Sexjobs - De Erotische Marktplaats',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/gay-en-bi': {
    title: 'Gay en Bi advertenties! Kijk snel op Sexjobs.nl',
    description:
      'Gay en Bi advertenties. Hier vind je het meest complete overzicht van de heetste gay en bi advertenties. Zowel betaalde als gratis sexcontacten!',
  },
  '/erotische-vacatures': {
    title: 'Erotische vacatures voor pikant werk! Kijk snel op Sexjobs.nl',
    description:
      'Erotische vacatures overzicht voor verschillende baantjes in de Erotiek. Dagelijks nieuwe erotische vacatures, dus kom snel kijken en vind jouw droombaan.',
  },
  '/gangbang/bukkake': {
    title: 'Meedoen met een Bukkake? Kijk snel op Sexjobs.nl',
    description:
      'Wil je mee doen met een Bukkake party? Bekijk hier het grootste aanbod met bukkake party advertenties. De bukkake party agenda is altijd up to date!',
  },
  '/mannen': {
    title: 'Mannen gevraagd of aangeboden. Kijk snel op Sexjobs.nl',
    description:
      'Deze mannen zoeken contact met jou! Overzicht van verschillende mannen advertenties die op zoek zijn naar dames die een escort of massage willen!',
  },
  '/sexartikelen-en-diensten': {
    title: 'Sexartikelen advertenties! Kijk snel op Sexjobs.nl',
    description:
      'Sexartikelen overzicht. Ben jij op zoek naar sexartikelen of bied je sexartikelen aan? Kom dan dagelijks kijken naar wat er allemaal nieuw is!',
  },
  '/betaling-in-natura': {
    title: 'Zoek je betaling in natura advertenties? Kijk op Sexjobs.nl',
    description:
      'Betaling in Natura advertenties zie je steeds vaker. Ben jij op zoek naar een automonteur, kapper of klusjesman die aan betaling in natura doet? Kijk hier!',
  },
  '/gratis-sexcontact/gangbang-contact': {
    title: 'Gangbang afspraak maken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar Gangbang contact? Bekijk hier het grootste aanbod van gangbang contact advertenties. Maar hier je keuze en plan je gangbang party!',
  },
  '/': {
    title: 'Sexjobs - De Erotische Marktplaats',
    description:
      "Sexjobs. De erotische marktplaats! Voor gratis en betaald sexcontact, sexparty's en het ruilen, kopen & verkopen van nieuwe en gebruikte sexartikelen.",
  },
  '/about-us': {
    title: 'Over Sexjobs.nl | Van toen tot nu en de toekomst.',
    description:
      'Over Sexjobs is het verhaal hoe het allemaal is begonnen en wat het nu is. Wat is Sexjobs, voor wie is Sexjobs en hoe werkt Sexjobs. Sexjobs is er voor jou!',
  },
  '/betaling-in-natura/klusjesman': {
    title: 'Klusjesman nodig voor onderhoud? Kijk snel op Sexjobs.nl',
    description:
      'Klusjesman aangeboden of op zoek naar een klusjesman? Bekijk hier het grootste aanbod klusjes en onderhoud advertenties die betaling in natura aanbieden.',
  },
  '/cam-en-telefoonsex/chat-whatsapp': {
    title: 'Afspreken via whatsapp, sms of kik? Kijk snel op Sexjobs.nl',
    description:
      'Zin in sex via Whatsapp, SMS of Kik? Bekijk hier het grootste aanbod van Whatsapp, SMS en Kik advertenties. Hier vind je zeker een geile dame voor jezelf!',
  },
  '/cam-en-telefoonsex/telefoonseks': {
    title: 'Zin in telefoonsex? Kijk snel op Sexjobs.nl',
    description:
      'Zin in Telefoonsex? Bekijk hier het grootste aanbod van telefoonsex advertenties. Kom voor beeldbellen, aftrekinstructies of een erotisch telefoongesprek!',
  },
  '/cam-en-telefoonsex/webcam-seks': {
    title: 'Zin in webcamsex? Kijk snel op Sexjobs.nl',
    description:
      'Zin in Webcamsex? Bekijk hier het grootste aanbod van online webcam sex advertenties! Deze geile dames zijn nu live online via sexcams. Kom webcammen!',
  },
  '/cookie-statement': {
    title: 'Cookie statement | Sexjobs.nl',
    description: 'Cookie statement van Sexjobs.nl',
  },
  '/dames-van-plezier/hotel-escort-ontvangst': {
    title: 'Hotel escort of ontvangst nodig? Kijk snel op Sexjobs.nl',
    description:
      'Zoek je een hotel escort of een dame die daar ontvangt? Bekijk hier ons grootste aanbod hotel escort en ontvangst advertenties. Gewoon lekker neuken.',
  },
  '/dames-van-plezier/milf': {
    title: 'Rijpe vrouw neuken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar rijpe vrouwen om te neuken? Bekijk hier ons grootste aanbod rijpe vrouwen advertenties. Amateur hoeren voor thuisontvangst en escort!',
  },
  '/events': {
    title: 'De grootste events agenda',
    description:
      'Ben jij opzoek naar events zoals gangbangs, barebangst, thema en of erotische feesten bekijk dan ons grootste aanbod aan evenementen.',
  },
  '/events/barebang': {
    title: 'Meedoen met een barebang? Kijk snel op Sexjobs.nl',
    description:
      'Wil je mee doen met een Barebang? Bekijk hier het grootste aanbod met bare gangbang party advertenties. De bare gangbang agenda is altijd up to date!',
  },
  '/events/bukkake': {
    title: 'Meedoen met een bukkake? Kijk snel op Sexjobs.nl',
    description:
      'Wil je mee doen met een Bukkake? Bekijk hier het grootste aanbod met bukkake gangbang party advertenties. De bukkake gangbang agenda is altijd up to date!',
  },
  '/events/gangbang': {
    title: 'Meedoen met een gangbang? Kijk snel op Sexjobs.nl',
    description:
      'Wil je mee doen met een Gangbang? Bekijk hier het grootste aanbod met gangbang party advertenties. De gangbang agenda is altijd up to date!',
  },
  '/events/themafeesten': {
    title: 'Wil je erotisch uitgaan? Kijk snel op Sexjobs.nl',
    description:
      'Wil je Erotisch uitgaan? Bekijk hier het grootste aanbod van erotisch uitgaan advertenties. De lijst wordt regelmatig aangevuld met nieuwe uitgaan tips!',
  },
  '/gay-en-bi/buiten-sex': {
    title: 'Gay en Bi buitensex',
    description:
      'Op zoek naar een gay of bi die aan buitensex doet? Bekijk ons grootste aanbod gay en bi buitensex advertenties.',
  },
  '/gay-en-bi/bdsm': {
    title: 'Gay en Bi BDSM',
    description:
      'Op zoek naar een gay of bi die aan BDSM doet? Bekijk ons grootste aanbod gay en bi BDSM advertenties.',
  },
  '/gratis-sexcontact/gangbang': {
    title: 'Gangbang afspraak maken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar Gangbang contact? Bekijk hier het grootste aanbod van gangbang contact advertenties. Maar hier je keuze en plan je gangbang party!',
  },
  '/gratis-sexcontact/plassex': {
    title: 'Plassex afspraak maken? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar plassex contacten? Bekijk hier het grootste aanbod van plassex advertenties. Zeer speciaal contact maar dan net iets extremer!',
  },
  '/mannen/prive-ontvangst': {
    title: 'Manen die aan thuisontvanst doen! Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een man die aan thuisontvangst doet? Bekijk ons grootste aanbod thuisontvangst advertenties. Ook amateurs doen thuisontvangst!',
  },
  '/mannen/bdsm': {
    title: 'Mannen die aan BDSM doen',
    description:
      'Op zoek naar een man die aan BDSM doet? Bekijk ons grootste aanbod BDSM advertenties.',
  },
  '/sexartikelen-en-diensten/fotos-dvds-en-videos': {
    title: "Op zoek naar foto's, dvd's of video's? Kijk snel op Sexjobs.nl",
    description:
      "Ben jij op zoek naar geile foto's, dvd's of juist video's? Bekijk hier het grootste aanbod van sex foto, dvd en video advertenties. Er is veel mogelijk!",
  },
  '/sexartikelen-en-diensten/lingerie-en-kleding': {
    title: 'Op zoek naar lingerie? Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar lingerie, kleding of schoenen? Bekijk het grootste aanbod van lingerie, kleding en laarzen.',
  },
  '/sexartikelen-en-diensten/fotografen': {
    title: 'Opzoek naar een fotograaf',
    description:
      "Fotografen aangeboden? Bekijk hier het aanbod van fotografen advertenties. Professioneel, discreet voor mooie erotische foto's en of video's.",
  },
  '/sexartikelen-en-diensten/striptease': {
    title: 'Opzoek naar een striptease',
    description:
      'Ben je opzoek naar een striptease bekijk ons grootste aanboed van striptease aanbieders.',
  },
  '/bdsm/bdsm-studios': {
    title: 'BDSM studies Het meest complete overzicht op Sexjobs.nl',
    description:
      "BDSM studio advertenties. Overzicht van goed aangeschreven BDSM studio's in Nederland. Hier vind je dominante meesteressen en onderdanige slaven.",
  },
  '/bdsm': {
    title: 'Ben jij opzoek naar een BDSM avontuur?',
    description:
      'Compleet BDSM overzicht. Van dominant, onderdanig tot kinky,extreem en bizar. Geen meesteres te extreem en geen geldslaaf is te rijk. Iedereen is welkom!',
  },
  '/bdsm/switch': {
    title: 'Switch nodig? Kijk snel op Sexjobs.nl',
    description:
      'Op zoek naar een Switch afspraak? Bekijk hier het grootste aanbod van sm switch advertenties. Vind je Kinky playmate, travestie slavin of switch begeleider!',
  },
  '/terms-condition': {
    title: 'Algemene voorwaarden | Sexjobs.nl',
    description: 'Algemene voorwaarden van Sexjobs.nl',
  },
  '/erotische-vacatures/bellen-mailen-en-chatten': {
    title: 'Mail of chat werk vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met Mail of Chatwerk? Bekijk hier het grootste aanbod van mail en chat werk advertenties. Regelmatig nieuwe vacatures online.',
  },
  '/erotische-vacatures/escorts': {
    title: 'Dames gevraagd vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Ben jij op zoek naar werk als Dame van plezier? Bekijk hier het grootste aanbod van dames gevraagd advertenties. Je kan meestal snel aan de slag!',
  },
  '/erotische-vacatures/gangbang': {
    title: 'Gangbang werk vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met Gangbang werk? Bekijk hier het grootste aanbod van gangbang werk advertenties. Regelmatig nieuwe vacatures online.',
  },
  '/erotische-vacatures/massage': {
    title: 'Erotische massage vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met het geven van erotische massages? Bekijk hier het grootste aanbod massage vacature advertenties. Regelmatig nieuwe vacatures!',
  },
  '/erotische-vacatures/modeling': {
    title: 'Modellen vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen als erotisch model? Bekijk hier het grootste aanbod van modellen vacatures. Van soft erotisch model tot echte hardcore modellen werk.',
  },
  '/erotische-vacatures/schoonmaak': {
    title: 'Schoonmaaktster vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met erotisch schoonmaken? Bekijk hier het grootste aanbod van schoonmakers vacatures. Regelmatig nieuwe vacatures!',
  },
  '/erotische-vacatures/webcamsex': {
    title: 'Webcamsex vacatures! Kijk snel op Sexjobs.nl',
    description:
      'Wil je geld verdienen met webcamsex? Bekijk hier het aanbod van erotisch webcasex vacatures. Er zijn verschillende aanbieders met goede verdiensten.',
  },
  '/nu-beschikbaar': {
    title: 'Alle adverteerders die nu beschikbaar zijn',
    description:
      'Ben je opzoek naar adverteerders die nu beschikbaar zijn kijk niet verder en bekijk ons grootste aanbod van adverteerders die nu direct beschikbaar zijn.',
  },
  '/gay-en-bi/buitensex': {
    title: 'Gay en Bi gangbang',
    description:
      'Op zoek naar een gay of bi die aan gangbang doet? Bekijk ons grootste aanbod gay en bi gangbang  advertenties.',
  },
};

const combinedMetaForLocattions = {
  '/[LOCATION]': {
    title: 'Zoek je een sexdate in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Dé erotische marktplaats met advertenties uit [LOCATION]! Gratis & betaald sexcontact, parties, ruilen & (ver)kopen van sexartikelen! | Betrouwbaar & Discreet',
  },
  '/dames-van-plezier/[LOCATION]': {
    title: 'Dames voor sexcontact in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Zoek je een sexdate in [LOCATION]? Kom direct in contact met vrouwen voor betaalde sex! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/dames-van-plezier/cardate/[LOCATION]': {
    title: 'Dames voor een cardate in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Deze geile dames laten jouw stoutste dromen uitkomen tijdens een cardate in [LOCATION]! Spreek direct af op Sexjobs.nl! | Betrouwbaar & Discreet',
  },
  '/dames-van-plezier/thuisontvangst/[LOCATION]': {
    title: 'Dames voor thuisontvangst in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Deze dames voor privé- & thuisontvangst in [LOCATION] laten jouw stoutste dromen uitkomen! Spreek direct af op Sexjobs.nl! | Betrouwbaar & Discreet',
  },
  '/dames-van-plezier/escort/[LOCATION]': {
    title: 'Sexdate met een escort in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Deze geile dames voor escort in [LOCATION] laten jouw stoutste dromen uitkomen! Spreek direct af op Sexjobs.nl! | Betrouwbaar & Discreet',
  },
  '/dames-van-plezier/hotel-escort-ontvangst/[LOCATION]': {
    title: 'Hotel escort & ontvangst in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Deze dames voor hotel escort & ontvangst in [LOCATION] laten jouw stoutste dromen uitkomen! Spreek direct af op Sexjobs.nl! | Betrouwbaar & Discreet',
  },
  '/cam-en-telefoonsex/[LOCATION]': {
    title: ' ',
    description: ' ',
  },
  '/gratis-sexcontact/[LOCATION]': {
    title: 'Gratis sexcontact in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Op zoek naar GRATIS sexcontact in [LOCATION]? Spreek direct af op Sexjobs.nl! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/gratis-sexcontact/gangbang/[LOCATION]': {
    title: 'Gratis gangbang in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Op zoek naar een GRATIS gangbang in [LOCATION]? Spreek direct af op Sexjobs.nl! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/erotische-vacatures/[LOCATION]': {
    title: 'Zoek je erotisch werk in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Sexjobs.nl heeft een compleet overzicht van erotische vacatures in [LOCATION]. Reageer direct! | Betrouwbaar & Discreet',
  },
  '/events/[LOCATION]': {
    title: 'Zoek je een sex event in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Sexjobs.nl heeft een compleet overzicht van sex events in [LOCATION]. Geef je direct op! | Betrouwbaar & Discreet',
  },
  '/erotische-massage/[LOCATION]': {
    title: 'Erotische massage in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Sexjobs.nl heeft een compleet overzicht van erotische massages in [LOCATION]. Maak direct een afspraak! | Betrouwbaar & Discreet',
  },
  '/bdsm/[LOCATION]': {
    title: 'BDSM afspraak in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Zin in een BDSM-afspraak in [LOCATION]? Spreek direct af op Sexjobs.nl! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/mannen/[LOCATION]': {
    title: 'Mannen voor sexcontact in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Zoek je mannen voor een sexdate in [LOCATION]? Spreek direct af op Sexjobs.nl! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/gay-en-bi/[LOCATION]': {
    title: 'Gay & bisexcontact in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Zin in gay of bisexcontact in [LOCATION]? Maak direct een afspraak op Sexjobs.nl! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/gay-en-bi/gratis-sexcontact-label/[LOCATION]': {
    title: 'Gratis gay & bisexcontact in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Op zoek naar GRATIS gay of bisexcontact in [LOCATION]? Spreek direct af op Sexjobs.nl! Dagelijks nieuwe sexadvertenties. | Betrouwbaar & Discreet',
  },
  '/sexartikelen-en-diensten/[LOCATION]': {
    title: 'Sexartikelen & diensten in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Op zoek naar sexartikelen of -diensten in [LOCATION]? Op Sexjobs.nl worden dagelijks nieuwe advertenties geplaatst! | Betrouwbaar & Discreet',
  },
  '/betaling-in-natura/[LOCATION]': {
    title: 'Betaling in natura in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Op zoek naar sexadvertenties met betaling in natura in [LOCATION]? Op Sexjobs.nl worden dagelijks nieuwe advertenties geplaatst! | Betrouwbaar & Discreet',
  },
  '/diversen/[LOCATION]': {
    title: 'Sexgerelateerde advertenties in [LOCATION]? Kijk snel op Sexjobs.nl!',
    description:
      'Sexjobs.nl heeft een compleet overzicht van sexgerelateerde advertenties in [LOCATION]. Dagelijks nieuwe advertenties! | Betrouwbaar & Discreet',
  },
};

export const getRouterBasePath = key => {
  const [absoluteRoutePath] = key.split('?');
  return meta[absoluteRoutePath];
};

export const getMetadataWithLocation = ({ metaPath = '', location = '' } = {}, path) => {
  const currentMeta = combinedMetaForLocattions[metaPath] || getRouterBasePath(`/${path}`);

  const { title = '', description = '' } = currentMeta || {};

  const modifiedLocation = convertCapitalize(location.replace('-', ' '));

  return {
    metaTitle: title.replace('[LOCATION]', modifiedLocation),
    metaDescription: description.replace('[LOCATION]', modifiedLocation),
  };
};
