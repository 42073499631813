import { memo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import cookies from 'js-cookie';
import { AiOutlineDoubleLeft } from 'react-icons/ai';
import * as AI from 'react-icons/ai';
import * as BS from 'react-icons/bs';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { MenuListUl, AsideWrapper, AsideHeader, AsideBody } from './styles';
import { ShortLogo } from 'app/components/Shared/UI';
import AsideMenuList from 'app/dummyData/DashboardMenu';
import { StyleConstants } from 'styles/StyleConstants';
import { Tooltip } from 'plugins';
import { removeFromStorage } from 'utils/persist';

const MenuPart = ({ data = [], isOpen, setLogOutModal }) => {
  const logOut = () => {
    setLogOutModal(true);
  };

  return (
    <>
      <MenuListUl className="every-menu-list" isOpen={isOpen}>
        {data.map((e, index) => {
          if (e.asTitle) {
            return (
              <li className="as-title" key={e.title}>
                {e.title}
              </li>
            );
          } else {
            const Icon = AI[e.icon] || BS[e.icon];
            return (
              <Tooltip
                direction="right"
                bg={StyleConstants.ASIDE_BODY_BG}
                key={index + e.title}
                content={e.title}
                maxWidth={100}
                containerId="tooltip-container"
                disableTooltip={isOpen}
              >
                {e.pathname ? (
                  <li className="list-item">
                    <NavLink exact to={e.pathname} activeClassName="selected-nav-link">
                      <Icon className="menu-list-icon" />
                      <span className="menu-list-item--text">{e.title}</span>
                    </NavLink>
                  </li>
                ) : (
                  <li key={index + e.title} className="list-item" onClick={logOut}>
                    <button>
                      <Icon className="menu-list-icon" />
                      <span className="menu-list-item--text">{e.title}</span>
                    </button>
                  </li>
                )}
              </Tooltip>
            );
          }
        })}
      </MenuListUl>
    </>
  );
};

const AsideMenu = ({ isOpen, emitOpen, isMobileView }) => {
  const [scrollBarHeight, setScrollBarHeight] = useState(null);
  const [logOutModal, setLogOutModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const asideBarHeaderHeight = +StyleConstants.ASIDE_HEADER_HEIGHT.split('px')[0];
    setScrollBarHeight(window.innerHeight - asideBarHeaderHeight);
  }, [isMobileView]);

  const closeModal = useCallback(() => {
    setLogOutModal(false);
  }, []);

  const logOut = useCallback(() => {
    cookies.remove('authToken');
    removeFromStorage('authRefreshToken');
    history.push('/login');
    closeModal();
  }, [closeModal, history]);

  const paintModal = useCallback(() => {
    return (
      <Dialog
        onClose={closeModal}
        aria-labelledby="simple-dialog-title"
        open={logOutModal}
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">Logout</DialogTitle>
        <DialogContent>
          <p className="mb-0 text-basic">Do you want to logout?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="contained" size="small">
            Cancel
          </Button>
          <Button onClick={logOut} size="small" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [logOutModal, closeModal, logOut]);

  return (
    <AsideWrapper
      isOpen={isOpen}
      className={isOpen ? 'aside-is--open' : ''}
      mobileView={isMobileView}
    >
      <AsideHeader className="aside-header" isOpen={isOpen} mobileView={isMobileView}>
        <div className="logo-area">
          {!isOpen ? (
            <ShortLogo click={() => emitOpen(true)} />
          ) : (
            <AiOutlineDoubleLeft className="aside-close-icon" onClick={() => emitOpen(false)} />
          )}
        </div>
        {isOpen && !isMobileView ? <img src="/logo.png" alt="logo" className="main-logo" /> : ''}
      </AsideHeader>
      <AsideBody isOpen={isOpen} mobileView={isMobileView}>
        {scrollBarHeight ? (
          <SimpleBar style={{ maxHeight: scrollBarHeight }}>
            {AsideMenuList.map((itemsList, index) => (
              <MenuPart
                data={itemsList}
                key={index}
                isOpen={isOpen}
                setLogOutModal={setLogOutModal}
              />
            ))}
          </SimpleBar>
        ) : (
          ''
        )}
      </AsideBody>
      {paintModal()}
    </AsideWrapper>
  );
};

export default memo(AsideMenu);
