export const CATEGORIES = 'categories';
export const PRICING = 'pricing';
export const LABELS = 'labels';
export const FILTERS = 'filters';

export const getFilters = (data, key, filters) => {
  const result = Object.keys(filters).map(key => ({ label: key, value: key }));

  return result.reduce((acc, elem) => {
    if (data[key]?.label !== elem.label) {
      acc.push(elem);
    }

    return acc;
  }, []);
};

export const categoryOptions = [{ label: 'Category', value: 'category' }];

export const adsOptions = [
  { label: 'Show Ads', value: 'Show Ads' },
  { label: "Don't show Ads", value: "Don't show Ads" },
];

export const positionOptions = [
  { label: 'Above content', value: 'Above content' },
  { label: 'Below content', value: 'Below content' },
];

export const pricingOptions = [{ label: 'Quickie', value: 'quickie' }];

export const getSelectOptions = options =>
  options.map(option => ({
    ...option,
    value: option.label || option.name,
    label: option.label || option.name,
  }));

export const updateState = (state, updates) => {
  return {
    ...state,
    ...Object.keys(updates).reduce((acc, key) => {
      if (typeof updates[key] === 'object' && !Array.isArray(updates[key])) {
        acc[key] = {
          ...state[key],
          ...updateState(state[key] || {}, updates[key]),
        };
      } else {
        acc[key] = updates[key];
      }
      return acc;
    }, {}),
  };
};
