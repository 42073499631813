import { useCallback, useEffect, useState } from 'react';

import Select from './components/Select';
import {
  adsOptions,
  categoryOptions,
  FILTERS,
  getFilters,
  LABELS,
  positionOptions,
} from '../utils';

import { useLinkAndFilterStyles } from './styles';
import InputField from './components/InputField';
import { memo } from 'react';

const LinkAndFilter = ({
  data,
  isExistPath,
  resetData,
  urlStructure,
  adFilters,
  handleDataChange,
}) => {
  const { root } = useLinkAndFilterStyles();
  const [needToReset, setNeedToReset] = useState(false);

  const { content, filters, path } = data;
  const { isValidUrl, selectedCity, category_id, label_ids } = urlStructure || {};

  useEffect(() => {
    if (needToReset) {
      if (isValidUrl) {
        resetData(['path', 'title', 'description', 'heading']);
      }

      setNeedToReset(false);
    }
  }, [isValidUrl, needToReset, resetData]);

  const handleInputChange = useCallback(
    e => {
      // TODO::: NEED TO REPLACE
      handleDataChange({ path: e.target.value });
      const timerId = setTimeout(() => {
        !needToReset && setNeedToReset(true);
        clearTimeout(timerId);
      }, 1000);
    },
    [handleDataChange, needToReset],
  );

  const selectedCategory = adFilters.categories.find(({ id }) => id === category_id);
  const selectedLabel = selectedCategory?.sections.find(({ id }) => id === label_ids?.[0]);

  return (
    <div className={root}>
      <div>
        <InputField
          title="Landing page URL"
          value={path}
          boxTitle="https://www.sexjobs.nl/"
          handleChange={handleInputChange}
          {...(isExistPath && { style: { marginBottom: '5px' } })}
        />
        {isExistPath && <div className="error">Url already exist</div>}

        <div className="level-group">
          <div className="flex">
            <Select
              label="Ads"
              value={content.ads}
              options={adsOptions}
              handleChange={e => handleDataChange({ content: { ads: e.target.value } })}
            />

            {content.ads === 'Show Ads' ? (
              <Select
                className="m-20"
                value={content.position}
                options={positionOptions}
                handleChange={e => handleDataChange({ content: { position: e.target.value } })}
              />
            ) : (
              <div className="full-width m-20" />
            )}
            <div className="full-width" />
          </div>
        </div>
        <div>
          <div className="flex">
            <Select
              disabled
              label="Level 1"
              options={categoryOptions}
              value={filters.category?.label}
            />
            <Select
              disabled={isValidUrl}
              type={FILTERS}
              className="m-20"
              label="Level 2"
              options={getFilters(filters, 'secondary', adFilters)}
              value={isValidUrl && selectedLabel ? 'label' : filters.primary?.label}
              handleChange={e =>
                handleDataChange({
                  filters: { primary: { label: e.target.value, value: undefined } },
                })
              }
            />
            <Select
              disabled={isValidUrl}
              type={FILTERS}
              label="Level 3"
              options={getFilters(filters, 'primary', adFilters)}
              value={isValidUrl && selectedCity ? 'location' : filters.secondary?.label}
              handleChange={e =>
                handleDataChange({
                  filters: { secondary: { label: e.target.value, value: undefined } },
                })
              }
            />
          </div>
          <div className="flex">
            <Select
              disabled={isValidUrl}
              options={adFilters.categories}
              handleChange={e =>
                handleDataChange({ filters: { category: { value: e.target.value } } })
              }
              value={isValidUrl && category_id ? selectedCategory.name : filters.category?.value}
            />
            <Select
              multiple={filters.primary?.label !== LABELS}
              className="m-20"
              disabled={!adFilters[filters.primary?.label]}
              handleChange={e =>
                handleDataChange({ filters: { primary: { value: e.target.value } } })
              }
              options={adFilters[filters.primary?.label]}
              value={isValidUrl && selectedLabel ? [selectedLabel.name] : filters.primary?.value}
            />
            <Select
              multiple={filters.secondary?.label !== LABELS}
              disabled={!adFilters[filters.secondary?.label]}
              handleChange={e =>
                handleDataChange({ filters: { secondary: { value: e.target.value } } })
              }
              options={adFilters[filters.secondary?.label]}
              value={isValidUrl && selectedCity ? [selectedCity] : filters.secondary?.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LinkAndFilter);
