import { HttpRequest } from 'services';

//@ts-nocheck

const apiUrl = '/contact-verification';
const apiAcUrl = '/account';
const activityUrl = '/activity';
const profileUrl = '/profile';
const profileAd = '/profile-ad';

export const accountDetailsService = {
  getAccountAdmins: () => {
    return HttpRequest.get(`/account?role=admin`);
  },

  getFraudStatus: account_id => {
    return HttpRequest.get(`/fraud/accounts/${account_id}/status`);
  },

  setFraudStatus: account_id => {
    return HttpRequest.post(`/fraud/accounts/${account_id}/status`);
  },

  getProfileAdConfigs: () => {
    return HttpRequest.get('profile-ad/configs');
  },

  manualVerify: account_id => {
    return HttpRequest.put(`account/verification?account_id=${account_id}`);
  },

  getAccountDetails: id => {
    return HttpRequest.get(apiAcUrl + '/' + id);
  },

  getAccountsBulk: body => {
    return HttpRequest.post(`${apiAcUrl}/bulk`, body);
  },

  getProfilesBulk: body => {
    return HttpRequest.post(`${profileUrl}/bulk`, body);
  },

  getProfilesBulkByAccountIds: body => {
    return HttpRequest.post('profile/profile/accounts/bulk', body);
  },

  getOrdersListing: body => {
    return HttpRequest.post('commerce/orders/filter', body);
  },

  getInvoicePdf: ({ id, accountId, env = 'cellar' }) => {
    return HttpRequest.get(
      `/file-generator/invoices/pdf?order_id=${id}&account_id=${accountId}&initializing_environment=${env}`,
    );
  },

  getViewInvoicePdf: ({ id, accountId }) => {
    return HttpRequest.get(`/commerce/orders/${id}/view?account_id=${accountId}`);
  },

  getDownloadAllInvoiceZip: body => {
    return HttpRequest.post('/file-generator/invoices/zip', body);
  },

  getAdProducts: body => {
    return HttpRequest.post('commerce/ad/products', body);
  },

  cancelAdProducts: body => {
    return HttpRequest.post('commerce/ad/product/cancel', body);
  },

  getPoliceClaimUrl: body => {
    return HttpRequest.post('file-generator/claims/police', body);
  },

  getSuspectedAccountsListing: body => {
    return HttpRequest.post('fraud/accounts/match', body);
  },

  getSuspectedAccountsListingCount: body => {
    return HttpRequest.post('fraud/accounts/match/count', body);
  },

  getAccountAdsDetails: id => {
    return HttpRequest.get(`aggregator/account-ads/${id}`);
  },

  getOnlineAd: referenceId => {
    return HttpRequest.get(`aggregator/online-ad/${referenceId}`);
  },

  banAccount: (id, body) => {
    return HttpRequest.post(`account/${id}/disable`, body);
  },

  unbanAccount: (id, body) => {
    return HttpRequest.post(`account/${id}/enable`, body);
  },

  removeAccount: id => {
    return HttpRequest.delete(`${apiAcUrl}?account_id=${id}`);
  },

  getContactInfo: id => {
    return HttpRequest.get(`profile-ad/group/contacts?account_id=${id}`);
  },

  getAccountCreditsCount: id => {
    return HttpRequest.get(`commerce/credits/count?account_id=${id}`);
  },

  addOrSubtractCredits: body => {
    return HttpRequest.put('commerce/credits', body);
  },

  getAccountDiscount: id => {
    return HttpRequest.get(`commerce/discounts?account_id=${id}`);
  },

  setAccountDiscount: body => {
    return HttpRequest.put('commerce/discounts', body);
  },

  checkContactVerification: (contactParams, contactTypeUrl) => {
    return HttpRequest.post(apiUrl + contactTypeUrl, contactParams);
  },

  sendContactVerification: (contactParams, contactTypeUrl) => {
    return HttpRequest.post(apiUrl + `/${contactTypeUrl}`, contactParams);
  },

  manualVerifyPhoneNumber: params => {
    return HttpRequest.post('/contact-verification/verify-phone-number/manual', params);
  },

  resetPass: ({ id, params }) => {
    return HttpRequest.post(apiAcUrl + `/password/reset?account_id=${id}`, params);
  },

  resetMemo: ({ id, params }) => {
    return HttpRequest.post(apiAcUrl + `/memos?account_id=${id}`, params);
  },

  getAccountConfigs: () => {
    return HttpRequest.get(apiAcUrl + '/configs');
  },

  getGeographySuggest: params => {
    return HttpRequest.post('geography/locations', params);
  },

  //activity logs

  getConfigs: () => {
    return HttpRequest.get(activityUrl + '/configs');
  },

  getLogs: ({ account_id, params, limit, offset, order_dir, order_by }) => {
    return HttpRequest.put(
      activityUrl +
        `/logs?account_id=${account_id}&limit=${limit}&offset=${offset}&order_dir=${order_dir}&order_by=${order_by}`,
      params,
    );
  },

  createLog: ({ account_id, params }) => {
    return HttpRequest.post(activityUrl + `/logs?account_id=${account_id}`, params);
  },

  //profiles

  getProfiles: account_id => {
    return HttpRequest.get(profileUrl + `?account_id=${account_id}`);
  },

  //profile-verification

  idOrPersonVerification: data => {
    return HttpRequest.post('profile-verification', data);
  },

  getVerifiedImages: id => {
    return HttpRequest.get(`profile-verification/${id}`);
  },

  getVerificationsBulk: ids => {
    return HttpRequest.post('/profile-verification/bulk', {
      profile_ids: ids,
    });
  },

  getVerificationConfig: () => {
    return HttpRequest.get(`/profile-verification/configs`);
  },

  companyVerificationCheck: data => {
    return HttpRequest.post('/profile-verification/check', data);
  },

  getCompanyVerificationCheck: accountId => {
    return HttpRequest.get(`/profile-verification/company/accounts/${accountId}`);
  },

  getCompanyProfileByAccountId: accountId => {
    return HttpRequest.get(`/profile/company?account_id=${accountId}`);
  },

  updateCompanyProfile: ({ profileId, data }) => {
    return HttpRequest.put(`/profile/company/${profileId}`, { data });
  },

  processCompanyProfile: (profileId, data) => {
    return HttpRequest.post(`/profile/process/company/${profileId}`, data);
  },

  approveOrDecline: ({ id, body }) => {
    return HttpRequest.post(`profile-verification/process/${id}`, body);
  },

  pinPhoto: body => {
    return HttpRequest.post('profile-verification/pin', body);
  },

  getPinedPhoto: id => {
    return HttpRequest.get(`profile-verification/pin?account_id=${id}`);
  },

  deletePhoto: params => {
    return HttpRequest.delete(`profile-verification/process/${params.id}?image_id=${params.imgId}`);
  },

  //ad
  getProfileAd: id => {
    return HttpRequest.get(profileAd + `/${id}`);
  },

  getExpenses: body => {
    return HttpRequest.post('/commerce/orders/expenses', body);
  },

  getCredit: id => {
    return HttpRequest.get(`/commerce/orders/${id}`);
  },

  getProfileAdPhotos: (id, purpose = 'ad') => {
    return HttpRequest.get(`profile/images?ad_id=${id}&purpose=${purpose}`);
  },

  getAdVerifPhotos: id => {
    return HttpRequest.get(`profile/images?profile_id=${id}&purpose=verification`);
  },

  getProfileById: id => {
    return HttpRequest.get(`profile/temp/${id}`);
  },

  updateProfileById: (id, body) => {
    return HttpRequest.put(`profile/temp/${id}`, body);
  },

  updateProfileAd: (id, data) => {
    return HttpRequest.patch(`profile-ad/${id}`, data);
  },

  // getGeograpy: (data) => {
  // 	return HttpRequest.post('geography/autocomplete', data);
  // },

  approveOrDeclineProfileAd: (id, body) => {
    return HttpRequest.post(`profile-ad/admin/process/${id}`, body);
  },

  approveOrDeclineProfile: (id, body) => {
    return HttpRequest.post(`profile/process/${id}`, body);
  },

  getProfileAds: id => {
    return HttpRequest.get(`profile-ad/account/${id}`);
  },

  updateAutoApprove: (id, body) => {
    return HttpRequest.post(`/account/autoapprove/${id}`, body);
  },

  getAccountProfileAds: id => {
    return HttpRequest.get(`profile-ad/profile/${id}`);
  },

  getProfileConfigs: () => {
    return HttpRequest.get(`${profileUrl}/configs`);
  },

  getProfileImages: query => {
    return HttpRequest.get(`${profileUrl}/images?${query}&purpose=ad`);
  },

  approveOrDeclinePhoto: ({ body, key }) => {
    return HttpRequest.post(`profile/images/${key}`, body);
  },
  getTopClient: ({ accountId }) => {
    return HttpRequest.get(`commerce/expenses?account_id=${accountId}&months_count=${3}`);
  },
  getLatestPPCPaymentDate: ({ accountId }) => {
    return HttpRequest.get(`/payment/ppc/latest?account_id=${accountId}`);
  },
  getAdFiltersCount: accountId => {
    return HttpRequest.get(`/aggregator/ads/account/${accountId}`);
  },
  getAdFilters: data => {
    return HttpRequest.post('aggregator/filters', data);
  },
  getProvinces: () => {
    return HttpRequest.get('aggregator/provinces?status=public');
  },
};
